const sr = {
    "1": "Da",
    "2": "Ne",
    "3": "Trial verzija - 30 dana.",
    "4": "Žalba je poslata putem aplikacije",
    "5": "Prihvaćeno",
    "6": "Dan prihvatanja",
    "7": "Akcija",
    "8": "Aktivan",
    "9": "Aktivnosti",
    "10": "Dodaj",
    "11": "Dodaj monzažera",
    "12": "Dodaj kompaniju",
    "13": "Dodaj dilera",
    "14": "Dodaj proizvođača",
    "15": "Dodaj property menadžera",
    "16": "Dodaj korisnika",
    "17": "Dodatno polje",
    "18": "Izmena polja \"dodatno ime\"",
    "19": "Dodatni opis",
    "20": "Dodatne informacije",
    "21": "Dodatni proizvodi",
    "22": "Prilagođavanje",
    "23": "Admin",
    "24": "Administrativni pregled",
    "25": "Svi elementi su dostavljeni na odredište?",
    "26": "Sve pozicije su instalirane",
    "27": "Sva prava zadržana",
    "28": "Da li ste sigurni da želite da obrišete ovog proizvođača?",
    "29": "Da li ste sigurni da želite da obrišete ovog korisnika?",
    "30": "Montirano",
    "31": "Montažer",
    "32": "Montažeri",
    "33": "Montaža",
    "34": "Montaža završena",
    "35": "Datum montaže",
    "36": "Zadaci",
    "37": "Uputstva za održavanje",
    "38": "Certificate details",
    "39": "Sertifikat(i)",
    "40": "Čišćenje",
    "41": "Obriši",
    "42": "Zatvoriti",
    "43": "Boja",
    "44": "Boja/Dekor",
    "45": "Provizija",
    "46": "Kompanija",
    "47": "Detalji kompanije",
    "48": "Informacije o kompaniji",
    "49": "Smer kompasa",
    "50": "Žalbe",
    "51": "Projekat završen",
    "52": "Završeno",
    "53": "Povezani prozor",
    "54": "Gradilište čisto",
    "55": "Datum kreiranja",
    "56": "Krajnji korisnik",
    "57": "Broj kupca",
    "58": "Referenca elementa",
    "59": "Datum",
    "60": "Raspon datuma",
    "61": "Diler",
    "62": "Obriši kompaniju",
    "63": "Obriši proizvođača",
    "64": "Obriši korisnika",
    "65": "Datum isporuke",
    "66": "Isporučeno bez oštećenja",
    "67": "Opis",
    "68": "Naznačeno mesto montaže",
    "69": "Prikaži_MENU_podatke po strani",
    "70": "Distribucija",
    "71": "Dokumenta",
    "72": "Istok",
    "73": "Izmeni montažera",
    "74": "Izmeni kompaniju",
    "75": "Izmeni proizvođača",
    "76": "Izmeni property menadžera",
    "77": "Izmeni korisnika",
    "78": "Širina x visina elementa",
    "79": "Element sa ovim id-jem ne postoji",
    "80": "Elementi",
    "81": "Email sačuvan.",
    "82": "Unesi boju",
    "83": "Unesi proviziju",
    "84": "Unesi ime kompanije",
    "85": "Unesi email",
    "86": "Unesi ekstenziju",
    "87": "Unesi visinu",
    "88": "Unesi IP adresu",
    "89": "Unesi ime",
    "90": "Unesi broj",
    "91": "Unesi broj pozicija",
    "92": "Unesi lozinku",
    "93": "Unesi telefon",
    "94": "Unesi broj telefona",
    "95": "Unesi grad",
    "96": "Unesi količinu",
    "97": "Unesi ulicu",
    "98": "Unesi prezime",
    "99": "Unesi korisničko ime",
    "100": "Unesi poreski broj",
    "101": "Unesi website",
    "102": "Unesi širinu",
    "103": "Greška prilikom čuvanja podataka na serveru.",
    "104": "Error while saving email data.",
    "105": "Zameniti hardver",
    "106": "isteklo",
    "107": "ističe",
    "108": "Ekstenzija",
    "109": "Proizvođač",
    "110": "Fabrika",
    "111": "Filter",
    "112": "filtrirano od _MAX_ ukupnih zapisa",
    "113": "Prvi",
    "114": "Poništi",
    "115": "Informacije o hardveru",
    "116": "Komarnici",
    "117": "Otisak",
    "118": "Komentari",
    "119": "Iz aplikacije",
    "120": "Menadžement panel",
    "121": "Čistač stakla",
    "122": "Zamena stakla",
    "123": "Struktura stakla",
    "124": "Širina x visina stakla",
    "125": "Održavanje hardvera",
    "126": "Zamena hardvera",
    "127": "Visina",
    "128": "Kako čistiti?",
    "129": "Slika",
    "130": "Informacije o elementu",
    "131": "Informacije su ograničene",
    "132": "Ulaz",
    "134": "Mesto instalacije",
    "135": "unesi barkod",
    "136": "IP adresa",
    "137": "je obavezno",
    "138": "Jezik",
    "139": "Poslednji",
    "140": "Poslednje održavanje",
    "141": "Poslednja žalba",
    "142": "Poslednji servis",
    "143": "Link do artikla",
    "144": "Učitavanje",
    "145": "Svi elementi su po specifikaciji?",
    "146": "Odjavi se",
    "147": "Podaci za pristup email-u",
    "148": "Održavanje",
    "149": "Promenio",
    "150": "Proizveo",
    "151": "Master controller dizajn",
    "152": "Master Controller broj artikla",
    "153": "Master Controller serial number",
    "154": "Datum podudaranja",
    "155": "Vidljivost menija",
    "156": "Automatska potvrda barkoda",
    "157": "Više informacija o REHAU održivosti",
    "158": "Novi testni period",
    "159": "Sledeći",
    "160": "Element nije oštećen",
    "161": "Staklo nije oštećeno",
    "162": "Nije pronađen nijedan odgovarajući zapis",
    "163": "Nijedan element nije instaliran",
    "164": "Sever",
    "165": "Nije sastavljeno",
    "166": "Žao nam je, nijedan zapis nije pronađen",
    "167": "Broj",
    "168": "Broj elementara",
    "169": "Pregled projekta",
    "170": "Broj pozicija",
    "171": "uključeno",
    "172": "Datum porudžbine",
    "173": "Broj porudžbine",
    "174": "Order progress",
    "175": "Izlaz",
    "176": "Šifra",
    "177": "Telefon",
    "178": "Grad",
    "179": "PVC čistač",
    "180": "Pozicija dokumenta",
    "181": "Pozicija detalja elementa",
    "182": "Pozicija sa ovim id-jem ne postoji",
    "183": "Pozicije",
    "184": "Prethodni",
    "185": "Primarni korisnički akreditivi",
    "186": "Štampaj podatke o prijavi",
    "187": "Obrada",
    "188": "Proizvođač",
    "189": "Sistem profila",
    "190": "Projekat",
    "191": "Project number",
    "192": "Upravljanje vlasništvom",
    "193": "Količina",
    "194": "Žalba",
    "195": "Molimo Vas da selektujete stanicu",
    "196": "Obrisani sadržaj",
    "197": "Popravka",
    "198": "Popravka dodataka",
    "199": "Popravka površine",
    "200": "Prijavljeno iz aplikacije",
    "201": "Prijavljeno iz menadžment pregleda",
    "202": "Resetuj testni period",
    "203": "Rezultat",
    "204": "Uloge",
    "205": "Sačuvaj",
    "206": "Uspešno sačuvano",
    "207": "Zameniti dihtung",
    "208": "Pretraga",
    "209": "Nivo bezbednosti",
    "210": "Podaci poslati REHAU",
    "211": "Servis",
    "212": "Servis i prodaja",
    "213": "Serviser",
    "214": "Podešavanja",
    "215": "Should a manual overall acceptance (or maintenance",
    "216": "Showing _START_ to _END_ of _TOTAL_ records",
    "217": "Prikaz 0 do 0 od 0 zapisa",
    "218": "Roletne",
    "219": "Prijavite se",
    "220": "Prijavite se da biste započeli svoju sesiju",
    "221": "Informacije o Smart staklu",
    "222": "Smart Prozor",
    "223": "Neki elementi su instalirani",
    "224": "Neki elementi nisu odgovarajući",
    "225": "Potrebna instalacija neke pozicije(pozicija)",
    "226": "Jug",
    "227": "Statistika",
    "228": "Ulica",
    "229": "Prezime",
    "230": "Održivost i energija",
    "231": "Telefon",
    "232": "Restartovanje testnog period uspešno",
    "233": "test period(i)",
    "234": "The properties have been retained",
    "235": "Postojeće žalbe",
    "236": "Ne postoji kompanija sa ovim id-jem",
    "237": "Ne postoje informacije o ovom elementu",
    "238": "Kompanija već postoji",
    "239": "Naziv",
    "240": "kome",
    "241": "Total acceptance",
    "242": "Total",
    "243": "Nepoznato",
    "244": "Dodaj logo",
    "245": "Korisnički podaci",
    "246": "Korisničko ime",
    "247": "Korisničko ime već postoji",
    "248": "Korisnici",
    "249": "Koeficijent toplotne provodljivosti",
    "250": "Koeficijent toplotne provodljivosti okvira",
    "251": "Koeficijent toplotne provodljivosti stakla",
    "252": "W.ID dodeljen",
    "253": "Upozorenje: ne oštećuj Window ID",
    "254": "Dobrodošli",
    "255": "Zapad",
    "256": "Širina",
    "257": "Ukupne dimenzije elementa (mm)",
    "258": "Površina prozora od",
    "259": "Površina prozora do",
    "260": "Broj elektronike prozora",
    "261": "Serijski broj elektronike prozora",
    "262": "Lokacija za instalaciju prozora",
    "263": "Prozorski sistem",
    "264": "Tip prozora",
    "265": "Zamena krila",
    "266": "Da li ste sigurni?",
    "267": "Poništi filter",
    "268": "Izmeni",
    "269": "Izvezi CSV",
    "270": "Čitač barkoda",
    "271": "ID stakla",
    "272": "Informacije",
    "273": "Link",
    "274": "Povezani korisnici",
    "275": "Email",
    "276": "Podešavanja emaila",
    "277": "Pošalji testni period",
    "278": "Ime servisnog ugovora",
    "279": "Servis kompanija",
    "280": "Telefon servisa",
    "281": "Broj servisa",
    "282": "Status",
    "283": "Station settings",
    "284": "Naslov",
    "285": "Tel.",
    "286": "Korisnik",
    "287": "Website",
    "288": "ID prozora",
    "289": "Prozorski sistem",
    "290": "Detalji krajnjeg korisnika",
    "291": "Ime",
    "292": "Dodaci za staklo",
    "293": "Podaci o hardveru",
    "294": "Naziv montažera",
    "295": "Montažna kompanija",
    "296": "Telefon montažera",
    "297": "Email montažera",
    "298": "URL",
    "299": "Država",
    "300": "Dodaj korisnika za ovu kompaniju",
    "301": "Dodati meni",
    "302": "Komandna tabla",
    "303": "Aplikacija",
    "304": "Dizajn početne stranice",
    "305": "Podešavanja korisnika",
    "306": "Potvrdi",
    "307": "Opis",
    "308": "Prilog",
    "309": "Id",
    "310": "Pozicija",
    "311": "Upozorenje",
    "312": "W.ID",
    "314": "Dileri",
    "315": "Dileri",
    "316": "Aktivan",
    "317": "Kompanija",
    "318": "Izvinite",
    "319": "Administrator",
    "320": "Korisnik",
    "321": "Poštanski broj",
    "322": "Logo",
    "323": "Pozadina",
    "324": "Poreski broj",
    "325": "CE sertifikati",
    "326": "Podešavanja aplikacije",
    "327": "Automatski",
    "328": "Uputstvo",
    "329": "Pregledaj",
    "330": "Učitavanje",
    "331": "Automatsko otpremanje dokumenata",
    "332": "Izvorni folder",
    "333": "Molimo Vas da odaberete putanju za automatsko otpremanje dokumenata.",
    "334": "Odaberite .zip fajl koji sadrži PPS XML fajl",
    "335": "Station",
    "336": "Ručno sceniranje",
    "337": "smart Glass stanica",
    "338": "Prenos informacija automatski ili ručno",
    "339": "Maksimalni broj traka u vremenskom periodu",
    "340": "QR kod od",
    "341": "QR kod do",
    "342": "Prefiks QR koda",
    "343": "Dužina QR koda",
    "344": "Podaci o bar kodu su neispravni.",
    "345": "Api ključ",
    "346": "Ubaci QR kod",
    "347": "Naziv stanice",
    "348": "Fajl nije selektovan",
    "349": "Fajl dodat",
    "350": "Greška prilikom dodavanja fajla",
    "351": "Ručno skeniranje je omogućeno",
    "352": "Molimo Vas da unesete QR kod",
    "353": "Nevažeći barkod",
    "354": "Barkod je uvezen",
    "356": "Nema podataka",
    "357": "Veza nije uspostavljena",
    "358": "Istorija",
    "359": "Bar kod je u upotrebi.",
    "360": "Greška prilikom učitavanja bar koda",
    "361": "Uputstvo",
    "362": "Od",
    "363": "kome",
    "364": "Dužina prefiksa QR koda mora biti kraća od dužine QR koda",
    "365": "Opseg bar koda je nevažeći",
    "366": "Nevažeći brojevi",
    "367": "Prethodno korišćeno",
    "368": "Korišćeno danas",
    "369": "Slobodno",
    "370": "Korišćeno do danas",
    "371": "Upotreba QR koda za trenutni opseg stanice.",
    "372": "Prevodi",
    "373": "Doradi prevod",
    "374": "Ključ",
    "375": "Engleski",
    "376": "Vrednost",
    "377": "ID za dodeljivanje",
    "378": "Pozicije prozora i vrata u građevinskom objektu",
    "379": "Servis za popravku / dokaz popravke",
    "380": "Održavanje i istorija",
    "381": "Protokol montaže",
    "382": "Modifikacija",
    "383": "Zameni staklo",
    "384": "Zameni krilo",
    "386": "Unos teksta",
    "387": "Selekcije će biti zadržane",
    "388": "Nove osobine",
    "389": "Dnevnik održavanja",
    "390": "Odmagljivanje stakla",
    "391": "Zakaži termin za održavanje",
    "392": "Do kompletne liste BV",
    "393": "Informacije o prozoru",
    "394": "Detalji instalacije",
    "395": "Boja",
    "396": "Širina x visina elementa u mm",
    "397": "Širina x visina stakla u mm",
    "398": "Odgovarajuće informacije",
    "399": "Ime instalatera",
    "400": "Datum / Vreme instalacije",
    "401": "Lokacija instalacije (GPS)",
    "402": "ID Uređaja JustSmart",
    "403": "pošalji",
    "404": "Pozicija filtera",
    "405": "Nijedan",
    "406": "Balkonska vrata",
    "407": "Kuhinja",
    "408": "Kupatilo",
    "409": "Soba",
    "410": "Krov",
    "411": "Instalacija",
    "412": "Sprat",
    "413": "Dodaj sliku",
    "414": "Potpis",
    "415": "čisto crtanje",
    "416": "Prozor bez oštećenja",
    "417": "Staklo bez oštećenja",
    "418": "Otvori kameru",
    "419": "Dodaj postojeću sliku",
    "420": "Instaliraj i dodeli prozor",
    "421": "Izveštaj o prijemu",
    "422": "Pošalji",
    "423": "Ceo građevinski projekat",
    "424": "Vrsta montaže",
    "425": "Eksplicitno za element",
    "426": "Ceo posao završen",
    "427": "Montažni protokoli pojedinačnih prozora",
    "428": "Postoji problem",
    "429": "Zatraži pomoć",
    "430": "Poruka",
    "431": "Pošalji",
    "432": "Sledeći element",
    "433": "Lista svih elemenata",
    "434": "Raspored izgradnje",
    "435": "putem broje porudžbine",
    "436": "putem skeniranja QR koda",
    "437": "pokaži pojedinačno",
    "438": "ili",
    "439": "Barkod broj",
    "440": "Prikaži informacije",
    "441": "Popravka/žalba",
    "442": "Prikaži kontakt osobu",
    "443": "Povratne informacije",
    "444": "Skeniraj drugi QR kod",
    "445": "Sledeće održavanje",
    "446": "Dobavljač prozora",
    "447": "Svojstva",
    "448": "Vrednost zvučne izolacije",
    "449": "Uf",
    "450": "Retrofit solutions / mogućnost proširenja",
    "451": "Kontakt",
    "452": "Tvoj Window ID",
    "453": "Šta želite da radite?",
    "454": "Redovno održavanje prozora produžuje njegov životni vek i komfor",
    "455": "Zatraži termin za održavanje putem kontakt forme",
    "456": "Zatraži termin za održavanje putem telefona",
    "457": "to their window craftsman",
    "458": "Ovim potvrđujem odredbe i uslove i GDPR uredbu.",
    "459": "Tvoja briga",
    "461": "Kontaktiraj putem…",
    "462": "Slomljeno staklo",
    "463": "Prozor se ne zatvara kako treba",
    "464": "Prozor je zaglavljen",
    "465": "Oštećen prozor",
    "466": "Ostali dodaci",
    "467": "Tvoji prozori i vrata su otvoreni",
    "468": "sastavila ova kompanija",
    "469": "Proizvođač prozora",
    "470": "Oceni prozore i kompaniju koja ih je ugradila sada",
    "471": "Kako ste zadovoljni sa uslugama kompanije?",
    "472": "Da li su isporučeni prozori u redu?",
    "473": "Da li Vam se dopadaju Vaši prozori?",
    "474": "Postojali su problemi sa obradom",
    "475": "Pozitivno",
    "476": "Šta biste poboljšali?",
    "477": "Uputstvo za montažu",
    "478": "Pozicije prozora i vrata",
    "479": "Otvori izveštaj montaže",
    "480": "Lista svih elemenata",
    "481": "Lista elemenata po broju porudžbine / QR",
    "482": "Pošalji dnevnik projekta",
    "483": "Detalji prozora",
    "484": "Pojedinačni detalji",
    "485": "Broj građevinskog projekta",
    "486": "Prozor",
    "487": "Pozicija prozora",
    "488": "Usluga popravke",
    "489": "Detalji održavanja",
    "490": "Kupac",
    "491": "Kontakt formular",
    "492": "Pronađi REHAU majstora u Vašoj blizini",
    "493": "Kategorija",
    "494": "QR skeniranje",
    "495": "Kontakt osoba",
    "496": "ažuriranje",
    "497": "filteri",
    "498": "Montaža završena",
    "499": "Montaža završena?",
    "500": "Isporuka bez oštećenja",
    "501": "Sačuvaj kao PDF",
    "502": "Uslikaj",
    "503": "Nevažeća email adresa",
    "504": "Instaliraj aplikaciju",
    "505": "Mesto",
    "506": "Lista projekta",
    "507": "Učitaj selektovani redosled?",
    "508": "Pogrešan broj porudžbine/barkod",
    "509": "Pojedinačni prozor",
    "510": "Unesi promene",
    "511": "Pošalji izveštaj putem emaila",
    "512": "Molimo Vas da selektujete 4 opcije",
    "513": "Sada skenirajte QR kod na prozoru da saznate na kojoj poziciji u zgradi treba da bude montiran.",
    "514": "Sačuvaj datoteku",
    "515": "Prikaži",
    "516": "rezultati",
    "517": "stranica",
    "518": "od",
    "519": "Datum od",
    "520": "Datum do",
    "521": "otvori",
    "522": "Veličina",
    "523": "Instalirano na",
    "524": "Uspeh",
    "525": "Informacije",
    "526": "Nova porudžbina učitana",
    "527": "ID Montažera",
    "528": "Prijavi se",
    "529": "Ovim potvrđujem odredbe i uslove i GDPR uredbu.",
    "530": "Odjavi se",
    "531": "skener",
    "532": "Pošalji protokol",
    "533": "Pošalji protokol u administraciju?",
    "534": "Dnevnik popravke",
    "535": "opcija 5",
    "536": "opcija 6",
    "537": "opcija 7",
    "538": "opcija 8",
    "539": "opcija 9",
    "540": "opcija 10",
    "541": "Email poslat.",
    "542": "Slanje emaila neuspešno.",
    "543": "Lubricate fogging",
    "544": "Prilagođeno",
    "545": "Očišćeno",
    "546": "Zapečaćeno",
    "547": "Izmenjeni dodaci",
    "548": "Komentari",
    "549": "Svi elementi su isporučeni na gradilište",
    "550": "Lokacija elemenata u okviru građevinskog projekta",
    "551": "Slanje protokola neuspešno!",
    "552": "Sačuvaj informacije protokola",
    "553": "Informacije su sačuvane.",
    "554": "Neuspešno čuvanje podataka.",
    "555": "Informacije Smart prozora",
    "556": "Prihvatanje Smart prozora",
    "557": "Broj elektronike prozora",
    "558": "Serijski broj elektronike prozora",
    "559": "Skeniraj QR kod (DMC) iz master contollera ili ga unesi ručno",
    "560": "Smart Window u potpunosti konfigurisan i funkcionalan",
    "561": "Svi vidljivi elementu su bez oštećenja/defekta",
    "562": "Da li je sistem za paljenje konektovan na 0-10V (0-100%)?",
    "563": "Lokacija zgrade za instalaciju",
    "564": "Započni skeniranje",
    "565": "Prekini skeniranje",
    "566": "Trenutno selektovana pozicija elementa",
    "567": "Maksimalno 4 atributa mogu biti selektovana u isto vreme.",
    "568": "Pos klijenta",
    "569": "Datum proizvodnje",
    "570": "Izmena Window.ID",
    "571": "Prethodni Window.ID",
    "572": "Novi Window.ID",
    "573": "Redni broj ne postoji ili je već u upotrebi.",
    "574": "Barkod",
    "575": "ID pozicije",
    "576": "Tip",
    "577": "ID elementa",
    "578": "Informacije o elementu",
    "579": "Window.ID",
    "580": "Molimo da se potpišete na predviđenom mestu",
    "581": "Završeno",
    "582": "Potpiši",
    "583": "Novi jezik",
    "584": "Izvorni jezik",
    "585": "Ime jezika",
    "586": "Kreiraj novi jezik",
    "587": "Dodaj jezik",
    "588": "Ažuriraj",
    "589": "ažuriranje u toku",
    "590": "Deaktivacija kompanije",
    "591": "Aktivacija kompanije",
    "592": "Da li ste sigurni da želite da deaktivirate ovu kompaniju?",
    "593": "Da li ste sigurni da želite da aktivirate ovu kompaniju?",
    "594": "Deaktivacija korisnika",
    "595": "Aktivacija korisnika",
    "596": "Da li ste sigurni da želite da deaktivirate ovog korisnika?",
    "597": "Da li ste sigurni da želite da aktivirate ovog korisnika?",
    "598": "ID pozicija",
    "599": "Sve",
    "600": "Uvezi/Izvezi",
    "601": "Molimo Vas da izaberete .csv jezik za uvoz datoteke",
    "602": "Uvezi",
    "603": "Izvezi",
    "604": "Datoteka",
    "605": "Prevodilac",
    "606": "Barkod datoteke",
    "607": "Molimo Vas da izaberete putanju za barkod datoteke.",
    "608": "Window_ID",
    "609": "Fenstersystem",
    "610": "clientPos",
    "611": "Farbe",
    "612": "BreBreite_Höhe_Element",
    "613": "Breite_Höhe_Glas",
    "614": "Aufbau_Glas",
    "615": "Beschlags_Info",
    "616": "matched_date",
    "617": "Datum_Uhrzeit",
    "618": "GPS_Koordinaten_LinkMaps",
    "619": "Nr",
    "620": "Montage",
    "621": "Stockwerk",
    "622": "Pos",
    "623": "Ignore starting character(s)",
    "624": "Ignore ending character(s)",
    "625": "Email - User activation",
    "626": "Activate companies",
    "627": "Trial expiration",
    "628": "Resend activation email",
    "629": "Dealer/Factory already exists",
    "630": "User is activated",
    "631": "Go to login page",
    "632": "User details",
    "633": "Add linked user",
    "634": "Delete user link",
    "635": "User is already linked",
    "636": "Username or password is empty",
    "637": "User not found",
    "638": "Send protokol to management",
    "639": "Assembly completed question",
    "640": "Option 5",
    "641": "Option 6",
    "642": "Option 7",
    "643": "Option 8",
    "644": "Option 9",
    "645": "Option 10",
    "646": "Assembler",
    "647": "Type",
    "648": "Date",
    "649": "Description",
    "650": "SchmierenBeschlag",
    "651": "Set",
    "652": "Clean",
    "653": "Seal",
    "654": "Replacement accessories",
    "655": "Comment",
    "656": "Connector",
    "657": "FSystem",
    "658": "StkNr",
    "659": "AuNummer",
    "660": "AuAlpha",
    "661": "Height",
    "662": "Width",
    "663": "Beschlagdaten",
    "664": "Glasbreite x Höhe",
    "665": "Glasaufbau",
    "666": "QR-CodeNr",
    "667": "Window Electric ID",
    "668": "Glass ID",
    "669": "Conditions",
    "670": "Add/Remove",
    "671": "And/Or",
    "672": "Group",
    "673": "Column",
    "674": "Condition",
    "675": "Enter Customer number",
    "676": "Translator",
    "678": "Application version control",
    "679": "Update could take a few minutes to finish",
    "680": "Awaiting new version",
    "681": "There is new application version, are you sure you want to update?",
    "682": "Application is up to date, do you want to restore old version?",
    "683": "Restore old version",
    "684": "Insert station name",
    "685": "Order number char filler",
    "686": "Please insert order number char filler",
    "687": "Order number max length",
    "688": "Please insert order number max length",
    "689": "MDB folder path",
    "690": "Please insert .mdb file path (used by importer when scanning for .mdb files)",
    "691": "XML folder path",
    "692": "Please insert .xml file path (used by importer when scanning for .xml files)",
    "693": "Ignore starting QR-Code character(s)",
    "694": "Ignore ending QR-Code character(s)",
    "695": "Designation",
    "696": "Length",
    "697": "Additional position",
    "698": "Smart window without damage",
    "699": "Smart window connected",
    "700": "Window orientation",
    "701": "Position element number",
    "702": "Smart window configured",
    "703": "Element without damage",
    "704": "Material number",
    "705": "Serial number",
    "706": "No image available",
    "707": "Construction site clean",
    "708": "Drag and drop image/pdf files here",
    "709": "File upload",
    "710": "File type",
    "711": "Order (all positions)",
    "712": "Deleted",
    "713": "Release notes",
    "714": "Height (mm)",
    "715": "Width (mm)",
    "716": "There is no panel for this position",
    "717": "Item",
    "718": "Bulk station",
    "719": "Bulk range",
    "720": "Window ID Last",
    "721": "Glass was replaced",
    "722": "Accessories were repaired",
    "723": "Sash was replaced",
    "724": "Surface was repaired",
    "725": "Accessories were repaired",
    "726": "Repair is completed",
    "727": "Confirm password",
    "728": "Wrong password",
    "729": "Passwords do not match",
    "730": "Password can not be empty",
    "731": "locatton",
    "732": "Timeline",
    "733": "UW_Wert",
    "734": "Special glass",
    "735": "There is no field for this position",
    "736": "Matched elements",
    "737": "Last matched element",
    "738": "Mounted elements",
    "739": "Last mounted element",
    "740": "Station deleted",
    "741": "Are you sure you want to delete station",
    "742": "Add new station",
    "743": "Delete order",
    "744": "Order deletion",
    "745": "Are you sure you want to delete order?",
    "746": "User added",
    "747": "User edited",
    "748": "Forgot password?",
    "749": "The Password field is required",
    "750": "The User name field is required",
    "751": "This user role is not authorized for dashboard login",
    "752": "User is not active",
    "753": "User company is not active or test period is over",
    "754": "Username or password is incorrect",
    "755": "Poštanski broj",
    "756": "Use scroll wheel to zoom picture in and out",
    "757": "Reset password",
    "758": "Email for password reset has been sent",
    "759": "Password has been reset",
    "760": "Email - Reset password",
    "761": "Element Nr",
    "762": "Order number from",
    "763": "Please insert order number from",
    "764": "Order number length",
    "765": "Please insert order number length",
    "766": "Order range from",
    "767": "Please insert order range from",
    "768": "Order range length",
    "769": "Please insert order range length",
    "770": "Please insert order piece from",
    "772": "Order piece length",
    "773": "Please insert order piece length",
    "774": "Position number from",
    "775": "Please insert position number from",
    "776": "Position number length",
    "777": "Please insert position number length",
    "778": "Position piece from",
    "779": "Please insert position piece from",
    "780": "Position piece length",
    "781": "Please insert position piece length",
    "782": "Security level",
    "783": "Barcode filler character",
    "784": "Please insert barcode filler character",
    "785": "back to projects",
    "786": "(read only)",
    "787": "limited access",
    "788": "read only access",
    "789": "own landingpage",
    "790": "Please insert connection string",
    "791": "DB Connection string",
    "792": "DB STD Connection string",
    "793": "DB  BWG Connection string",
    "794": "Database connection",
    "795": "Chat",
    "796": "Messagess",
    "797": "CE Document",
    "798": "Order Document",
    "799": "Last repair",
    "800": "Acceptance completed",
    "801": "New update vailable, please restart application",
    "802": "External link",
    "803": "External link prefix",
    "804": "Project reference",
    "805": "Task interval",
    "806": "Dashboard users",
    "807": "App users",
    "808": "Last upload",
    "809": "minutes",
    "810": "Matcher version",
    "811": "Importer version",
    "812": "Logout user",
    "813": "User logged out successfully",
    "814": "Barcode changed",
    "815": "Updated information",
    "816": "Directions",
    "817": "Install APP",
    "818": "Install the Window.ID APP by scanning the QR code",
    "819": "matched",
    "820": "not matched",
    "821": "Planner",
    "822": "Number of days",
    "823": "Settings for the maintenance reminder.",
    "824": "days",
    "825": "Next maintenance",
    "826": "Edit image",
    "827": "No filter",
    "828": "Add order",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image",
    "900": "Jump to page",
    "901": "Overall Acceptance",
    "902": "Some position elements already have acceptance information entered. Accepting everything will overwrite the existing data.",
    "903": "Impersonating company",
    "904": "Edit landingpage",
    "905": "Information about rack",
    "906": "Empty rack",
    "907": "Rack is empty",
    "908": "Rack status",
    "909": "Closed",
    "910": "Full",
    "911": "In use",
    "912": "Rack pin",
    "913": "Insert rack pin",
    "914": "Wrong pin",
    "915": "Not imported",
    "916": "Standort / Adresse",
    "917": "Auftragnehmer / Firma - Adresse",
    "918": "Element",
    "919": "Acceptance points",
    "920": "own WID logo/colors",
    "921": "Appearance",
    "922": "Monteur signature",
    "923": "Customer signature",
    "924": "Separator for auto upload (Documents)",
    "925": "Please insert global API Key",
    "926": "Maintenance report",
    "927": "Repair report",
    "928": "Please insert char separator for documents",
    "929": "Station not found in database",
    "930": "Barcode is empty",
    "931": "QRCode is in use",
    "932": "ERROR / WARNING",
    "933": "Matching did not work!",
    "934": "Barcode",
    "935": "Window-ID",
    "936": "Error message",
    "937": "Scan to confirm",
    "938": "QR-Code is empty",
    "939": "Technical details",
    "940": "Delivery",
    "941": "Rack.ID Station",
    "942": "Ignore Rack.ID starting cahracter(s)",
    "943": "Ignore Rack.ID ending cahracter(s))",
    "944": "Rack management",
    "945": "Colour order",
    "946": "Colour Inside / Colour Outside",
    "947": "Colour Outside / Colour Inside",
    "948": "Fabrika",
    "949": "Factory is required",
    "950": "Matcher - actual version",
    "951": "Assembly incomplete",
    "952": "Scanner settings",
    "953": "Camera selection",
    "954": "Camera",
    "955": "No cameras found.",
    "956": "Press RFID START to start scanner.",
    "957": "Stop preview",
    "958": "Start preview"
};

export default sr;