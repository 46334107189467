const it = {
    "1": "Sì",
    "2": "No",
    "3": "Versione di prova di 30 giorni",
    "4": "È stato segnalato un reclamo tramite l'app",
    "5": "Accettazione",
    "6": "Data di accettazione",
    "7": "Azione",
    "8": "Attivo",
    "9": "Attività",
    "10": "Aggiungi",
    "11": "Aggiungere posatore",
    "12": "Aggiungi azienda",
    "13": "Aggiungi rivenditore",
    "14": "Aggiungi fabbricatore di serramenti",
    "15": "Aggiungere la gestione della proprietà",
    "16": "Aggiungi utente",
    "17": "Campo aggiuntivo",
    "18": "Modifica del nome del campo aggiuntivo",
    "19": "Informazioni aggiuntive",
    "20": "Informazioni aggiuntive",
    "21": "Prodotti aggiuntivi",
    "22": "Regolazione",
    "23": "Amministrazione",
    "24": "Visualizzazione amministrativa",
    "25": "Tutti gli elementi sono stati consegnati in cantiere?",
    "26": "Tutte le posizioni sono posate",
    "27": "Tutti i diritti riservati.",
    "28": "Sei sicuro di voler eliminare questo fabbricante?",
    "29": "Siete sicuri di voler eliminare questo utente?",
    "30": "Posato",
    "31": "Posatore",
    "32": "Posatori",
    "33": "Montaggio",
    "34": "Installazione completata",
    "35": "Data di installazione",
    "36": "Compiti",
    "37": "Istruzioni per la manutenzione",
    "38": "Dettagli del certificato",
    "39": "Certificato/i",
    "40": "Sostituire il telaio",
    "41": "Libero",
    "42": "Chiudere",
    "43": "Colore",
    "44": "Colore/Finitura",
    "45": "Commissione",
    "46": "Azienda",
    "47": "Dettagli sull'azienda",
    "48": "Informazioni sull'azienda",
    "49": "Direzione bussola",
    "50": "Contestazioni",
    "51": "Progetto completato",
    "52": "Completato",
    "53": "Finestra collegata",
    "54": "Cantiere pulito",
    "55": "Data di creazione",
    "56": "Utente finale",
    "57": "Numero cliente",
    "58": "Riferimento dell'elemento",
    "59": "Data",
    "60": "Intervallo di data",
    "61": "Rivenditore",
    "62": "Cancellare l'azienda",
    "63": "Elimina fabbricatore di serramenti",
    "64": "Cancellare l'utente",
    "65": "Data di consegna",
    "66": "Consegnato senza danni",
    "67": "Descrizione",
    "68": "Luogo di posa in opera designato",
    "69": "Visualizza_MENU_ registrazioni per pagina",
    "70": "Distribuzione",
    "71": "Documenti",
    "72": "Est",
    "73": "Modifica posatore",
    "74": "Modifica azienda",
    "75": "Modifica fabbricatore di serramenti",
    "76": "Modifica della gestione degli immobili",
    "77": "Modifica utente",
    "78": "Larghezza x altezza del serramento",
    "79": "Il serramento con questo id non esiste",
    "80": "Serramenti",
    "81": "Dati e-mail salvati.",
    "82": "Inserire il colore",
    "83": "Inserire commissione",
    "84": "Inserire il nome dell'azienda",
    "85": "Inserire e-mail",
    "86": "Inserire l'estensione",
    "87": "Inserire l'altezza",
    "88": "Inserire l'indirizzo IP",
    "89": "Inserire il nome",
    "90": "Inserire il numero",
    "91": "Inserire il numero di posizioni",
    "92": "Inserire la password",
    "93": "Inserire il telefono",
    "94": "Inserire il numero di telefono",
    "95": "Inserire la città",
    "96": "Inserire la quantità",
    "97": "Inserisci la via",
    "98": "Inserire il cognome",
    "99": "Inserire il nome utente",
    "100": "Inserire UStIdNr",
    "101": "Inserisci sito web",
    "102": "Inserisci larghezza",
    "103": "Errore durante il salvataggio dei dati sul server.",
    "104": "Errore durante il salvataggio dei dati dell'e-mail.",
    "105": "Sostituire l'hardware",
    "106": "scaduto il",
    "107": "scadrà il",
    "108": "Estensione",
    "109": "Fabbricatore di serramenti",
    "110": "Fabbrica",
    "111": "Filtro",
    "112": "filtrati da _MAX_ record totali",
    "113": "Primo",
    "114": "Annulla",
    "115": "Informazioni sulla ferramenta",
    "116": "Zanzariere",
    "117": "Impronta di piede",
    "118": "Commenti",
    "119": "Da App",
    "120": "Da vista gestione",
    "121": "Pulizia vetri",
    "122": "Sostituzione del vetro",
    "123": "Struttura del vetro",
    "124": "Larghezza x altezza del vetro",
    "125": "Manutenzione della ferramenta",
    "126": "Sostituzione della ferramenta",
    "127": "Altezza",
    "128": "Come pulire",
    "129": "Immagine",
    "130": "Informazioni sull'elemento",
    "131": "Le informazioni sono limitate",
    "132": "Input",
    "134": "Luogo di posa in opera",
    "135": "Inserire il codice a barre",
    "136": "Indirizzo IP",
    "137": "è richiesto",
    "138": "Lingua",
    "139": "Ultimo",
    "140": "Ultima manutenzione",
    "141": "Ultimo reclamo",
    "142": "Ultimo servizio",
    "143": "Link all'articolo",
    "144": "Caricamento",
    "145": "Tutti gli elementi sono conformi alle specifiche?",
    "146": "Disconnessione",
    "147": "Dati di accesso alle e-mail",
    "148": "Manutenzione",
    "149": "Modificato da",
    "150": "Prodotto",
    "151": "Design del controller master",
    "152": "Numero di materiale del controllore master",
    "153": "Numero di serie del controllore master",
    "154": "Data di abbinamento",
    "155": "Visibilità del menu",
    "156": "Conferma automatica del codice a barre",
    "157": "Ulteriori informazioni su REHAU Sustainability",
    "158": "Nuovo periodo di prova",
    "159": "Avanti",
    "160": "Nessun danno all'elemento",
    "161": "Nessun danno al vetro",
    "162": "Non sono stati trovati record corrispondenti",
    "163": "Nessuno degli elementi è stato posato",
    "164": "Nord",
    "165": "Non montato",
    "166": "Non è stato trovato - mi dispiace",
    "167": "Numero",
    "168": "Numero di elementi",
    "169": "Panoramica del progetto",
    "170": "Numero di posizioni",
    "171": "su",
    "172": "Data dell'ordine",
    "173": "Numero d'ordine",
    "174": "Stato dell'ordine",
    "175": "Uscita",
    "176": "Password",
    "177": "Telefono",
    "178": "Città",
    "179": "Detergente per PVC",
    "180": "Pos. Documento",
    "181": "Dettagli dell'elemento Posizione",
    "182": "La posizione con questo id non esiste",
    "183": "Posizioni",
    "184": "Precedente",
    "185": "Credenziali utente primario",
    "186": "Stampa i dati di accesso",
    "187": "Elaborazione",
    "188": "Fabbricatore di serramenti",
    "189": "Sistema di profili",
    "190": "Progetto",
    "191": "Numero del progetto",
    "192": "Gestione della proprietà",
    "193": "Quantità",
    "194": "Reclamo",
    "195": "Selezionare la stazione",
    "196": "Contenuto riciclato",
    "197": "Riparazione",
    "198": "Accessori per la riparazione",
    "199": "Superficie di riparazione",
    "200": "Segnalato da App",
    "201": "Segnalato dalla vista di gestione",
    "202": "Azzeramento del periodo di prova",
    "203": "Risultato",
    "204": "Ruolo",
    "205": "Salvare",
    "206": "Salvato con successo.",
    "207": "Sostituire le guarnizioni",
    "208": "Ricerca",
    "209": "Livello di sicurezza",
    "210": "Invio di dati a REHAU",
    "211": "Servizio",
    "212": "Servizio e vendita",
    "213": "Fornitore di servizi",
    "214": "Impostazioni",
    "215": "Si deve inserire ora un collaudo generale manuale (9,o una manutenzione, una riparazione)?",
    "216": "Visualizzazione da _inizio_ a _fine_ dei record _totali",
    "217": "Mostra da 0 a 0 di 0 record",
    "218": "Tapparelle/persiane",
    "219": "Accedi",
    "220": "Accedi per iniziare la tua sessione",
    "221": "Informazioni su Smart Glass",
    "222": "Smart Window",
    "223": "Alcuni elementi sono posati",
    "224": "Alcuni elementi non sono abbinati",
    "225": "Alcune posizioni devono essere posate in opera",
    "226": "Sud",
    "227": "Statistica",
    "228": "Via",
    "229": "Cognome",
    "230": "Sostenibilità e dati energetici",
    "231": "Telefono",
    "232": "Periodo di prova resettato con successo",
    "233": "periodo/i di prova",
    "234": "Le proprietà sono state mantenute",
    "235": "Esistono dei reclami",
    "236": "Non c'è nessuna azienda con questo id",
    "237": "Non ci sono informazioni su questo elemento",
    "238": "Questa azienda è già stata utilizzata",
    "239": "Titolo o qualifica?",
    "240": "a",
    "241": "Accettazione totale",
    "242": "Totale",
    "243": "Sconosciuto",
    "244": "Carica il logo",
    "245": "Amministrazione utente",
    "246": "Nome utente",
    "247": "Nome utente già preso.",
    "248": "Utenti",
    "249": "U value",
    "250": "Uf",
    "251": "Ug",
    "252": "W.ID assegnato",
    "253": "Attenzione a non danneggiare il Window.ID",
    "254": "Benvenuto",
    "255": "Ovest",
    "256": "Larghezza",
    "257": "Dimensione complessiva dell'elemento (mm)",
    "258": "Area della finestra da",
    "259": "Area della finestra in",
    "260": "Finestra Numero materiale elettronico",
    "261": "Numero di serie della finestra elettronica",
    "262": "Posizione di installazione della finestra",
    "263": "Sistema della finestra",
    "264": "Tipo di finestra",
    "265": "Sostituzione delle ante",
    "266": "Sei sicuro?",
    "267": "Cancella il filtro",
    "268": "Modifica",
    "269": "Esportazione CSV",
    "270": "Lettore di codici a barre",
    "271": "ID vetro",
    "272": "Informazioni",
    "273": "Link",
    "274": "Utenti collegati",
    "275": "Email",
    "276": "Impostazioni email",
    "277": "Periodo di prova dell'email",
    "278": "Titolo del servizio / qualifica",
    "279": "Società di servizi",
    "280": "Telefono del servizio",
    "281": "Email di servizio",
    "282": "Stato del servizio",
    "283": "Impostazioni della stazione",
    "284": "Oggetto",
    "285": "Tel.",
    "286": "Utente",
    "287": "Sito web",
    "288": "Window ID",
    "289": "Sistema della finestra",
    "290": "Dettagli dell'utente finale",
    "291": "Nome",
    "292": "Trucco in vetro",
    "293": "Dettagli hardware o ferramenta?",
    "294": "Titolo del posatore /qualifica",
    "295": "Azienda del posatore",
    "296": "Telefono del posatore",
    "297": "Email del posatore",
    "298": "URL",
    "299": "Paese",
    "300": "Si prega di aggiungere un utente per questa azienda",
    "301": "Menu aggiuntivo",
    "302": "Dashboard",
    "303": "App",
    "304": "Design della pagina principale",
    "305": "Gestione utenti",
    "306": "Conferma",
    "307": "Corpo",
    "308": "Allegato",
    "309": "Id",
    "310": "Posizione",
    "311": "Avviso",
    "312": "W.ID",
    "314": "Rivenditori",
    "315": "Attivare",
    "316": "Disattivare",
    "317": "azienda",
    "318": "Mi dispiace",
    "319": "Amministratore",
    "320": "utente",
    "321": "Codice postale",
    "322": "Logo",
    "323": "Sfondo",
    "324": "Partita IVA",
    "325": "CE Documents ? Marcatura CE?",
    "326": "Impostazioni dell'applicazione",
    "327": "Automatico",
    "328": "Manuale",
    "329": "Sfogliare",
    "330": "Caricamento",
    "331": "Caricamento automatico dei documenti",
    "332": "Cartella di origine",
    "333": "Scegliere il percorso della cartella per il caricamento automatico dei documenti.",
    "334": "Scegliere il file .zip contenente i file PPS XML",
    "335": "Stazione",
    "336": "Scansione manuale",
    "337": "Stazione Smart Glass",
    "338": "Trasferimento di informazioni",
    "339": "Numero massimo di nastri nella timeline",
    "340": "QR-Code da",
    "341": "fino a QR-Code",
    "342": "Prefisso del QR-Code",
    "343": "Lunghezza del QR-Code",
    "344": "I dati del codice a barre non sono validi.",
    "345": "Chiave Api",
    "346": "inserire QR-code",
    "347": "Nome della stazione",
    "348": "File non selezionato",
    "349": "File caricato",
    "350": "Errore di caricamento del file",
    "351": "La scansione manuale è abilitata",
    "352": "Inserire il codice a barre",
    "353": "Il codice a barre non è valido",
    "354": "Questo codice a barre è stato importato",
    "356": "Nessun dato",
    "357": "Connessione fallita",
    "358": "Cronologia",
    "359": "Il codice a barre è in uso.",
    "360": "Errore durante l'abbinamento del codice a barre",
    "361": "Manuale",
    "362": "Da",
    "363": "A",
    "364": "La lunghezza del prefisso del QR-Code deve essere inferiore alla lunghezza del QR-Code",
    "365": "L'intervallo del codice a barre non è valido",
    "366": "Numeri non validi",
    "367": "Utilizzato in precedenza",
    "368": "Utilizzato oggi",
    "369": "Libero",
    "370": "Utilizzato fino ad oggi",
    "371": "Utilizzo del numero di QR-Code per l'attuale gamma di stazioni",
    "372": "Traduzioni",
    "373": "Modifica traduzione",
    "374": "Chiave",
    "375": "Inglese",
    "376": "Valore",
    "377": "ID da assegnare",
    "378": "Posizione delle finestre e delle porte nel progetto",
    "379": "Servizio di riparazione / prova di riparazione",
    "380": "Manutenzione e cronologia interventi",
    "381": "Protocollo di montaggio",
    "382": "Modifica",
    "383": "Sostituzione del vetro",
    "384": "Sostituzione dell'anta",
    "386": "Inserimento del testo",
    "387": "Le selezioni vengono mantenute",
    "388": "Nuove funzioni",
    "389": "Registro delle manutenzioni effettuate",
    "390": "Demisting del vetro",
    "391": "Fissare un appuntamento per la manutenzione",
    "392": "All'elenco completo BV",
    "393": "Informazioni sulla finestra",
    "394": "Dettagli sulla posa in opera",
    "395": "Colore",
    "396": "Larghezza x altezza dell'elemento in mm",
    "397": "Larghezza x altezza del vetro in mm",
    "398": "Informazioni sul montaggio",
    "399": "Nome del posatore",
    "400": "Data/ora della posa in opera",
    "401": "Luogo di installazione (GPS)",
    "402": "ID dispositivo JustSmart",
    "403": "inviare",
    "404": "Filtro posizione",
    "405": "Nessuna",
    "406": "Porte finestra",
    "407": "Cucina",
    "408": "Bagno",
    "409": "Camera",
    "410": "Tetto",
    "411": "Installazione",
    "412": "Pavimento",
    "413": "Allegare foto",
    "414": "Firma",
    "415": "pulire il disegno",
    "416": "Finestra senza danni",
    "417": "Vetro senza danni",
    "418": "Aprire la fotocamera",
    "419": "Caricare un'immagine esistente",
    "420": "Posare in opera e assegnare la finestra",
    "421": "Verbale di accettazione",
    "422": "Inviare",
    "423": "Intero progetto di costruzione",
    "424": "Tipo di montaggio",
    "425": "Esplicito per elemento",
    "426": "Lavoro completato",
    "427": "Protocolli di montaggio di singole finestre",
    "428": "Esiste un problema",
    "429": "Richiesta di aiuto",
    "430": "Messaggio",
    "431": "Invia",
    "432": "Elemento successivo",
    "433": "Elenco con tutti gli elementi",
    "434": "Programma dei lavori di costruzione",
    "435": "tramite numero d'ordine",
    "436": "tramite scanner di codice QR",
    "437": "mostra singolo",
    "438": "o",
    "439": "Numero di codice a barre",
    "440": "Mostra informazioni",
    "441": "Riparazione/reclamo",
    "442": "Mostra il contatto di riferimento",
    "443": "Feedback",
    "444": "Scansione di un altro QR-code",
    "445": "Manutenzione successiva",
    "446": "Fornitore della finestra",
    "447": "Proprietà",
    "448": "Valore di isolamento acustico",
    "449": "Uf",
    "450": "Soluzioni di retrofit / opzioni di espansione",
    "451": "Contatto",
    "452": "Il tuo Window.ID",
    "453": "Cosa volete fare?",
    "454": "La manutenzione regolare delle finestre ne prolunga la durata e il comfort.",
    "455": "Richiedere un appuntamento per la manutenzione tramite il modulo",
    "456": "Richiedere un appuntamento per la manutenzione per telefono",
    "457": "al proprio artigiano delle finestre",
    "458": "Con la presente confermo i termini e le condizioni e il regolamento GDPR",
    "459": "Il vostro interesse",
    "461": "Contatto via ...",
    "462": "Vetro rotto",
    "463": "La finestra non si chiude bene",
    "464": "La finestra è bloccata",
    "465": "Finestra danneggiata",
    "466": "Altri accessori",
    "467": "Le vostre finestre e porte sono state",
    "468": "montate da questa azienda",
    "469": "Fabbricatore di finestre",
    "470": "Valutate ora le vostre finestre e l'azienda produttrice",
    "471": "Quanto siete soddisfatti del servizio offerto dall'azienda?",
    "472": "I prodotti consegnati vanno bene?",
    "473": "Come vi sembrano le vostre finestre?",
    "474": "Ci sono stati problemi con la lavorazione",
    "475": "Positivo",
    "476": "Cosa migliorereste?",
    "477": "Istruzioni di montaggio in opera",
    "478": "Posizioni delle finestre e delle porte",
    "479": "Aprire il rapporto di montaggio",
    "480": "Elenco completo degli elementi legati ad un numero d'ordine",
    "481": "Elenco degli elementi tramite numero d'ordine / QR",
    "482": "Inviare il registro di progetto",
    "483": "Dettagli finestra",
    "484": "Dettagli singoli",
    "485": "Numero del progetto di costruzione",
    "486": "Finestra",
    "487": "Posizione della finestra",
    "488": "Servizio di riparazione",
    "489": "Dettagli sulla manutenzione",
    "490": "Cliente",
    "491": "Modulo di contatto",
    "492": "Trova subito l'artigiano REHAU più vicino a te",
    "493": "Categoria",
    "494": "Scanner QR",
    "495": "Contatto",
    "496": "aggiornamento",
    "497": "filtri",
    "498": "Montaggio completato",
    "499": "Montaggio completato?",
    "500": "Consegna senza danni",
    "501": "Salva come PDF",
    "502": "Scattare una foto",
    "503": "Indirizzo e-mail non valido",
    "504": "Installare l'app",
    "505": "Luogo",
    "506": "Elenco progetti",
    "507": "Caricare l'ordine selezionato?",
    "508": "Numero d'ordine/codice a barre errato",
    "509": "Finestra singola",
    "510": "Inserire le modifiche",
    "511": "Invia il rapporto Rehau via e-mail",
    "512": "Scegliere 4 opzioni",
    "513": "Ora scansionate il codice QR della finestra per scoprire in quale posizione dell'edificio deve essere installato questo elemento.",
    "514": "Salvare il file",
    "515": "Mostra",
    "516": "risultati",
    "517": "pagina",
    "518": "di",
    "519": "Data da",
    "520": "Data a",
    "521": "aprire",
    "522": "Dimensione",
    "523": "Installato su",
    "524": "Successo",
    "525": "Info",
    "526": "Nuovo ordine caricato",
    "527": "ID assemblatore",
    "528": "Accesso",
    "529": "Confermo i termini e le condizioni generali e il regolamento GDPR.",
    "530": "Disconnessione",
    "531": "scanner",
    "532": "Invia il protocollo",
    "533": "Inviare il protocollo all'amministratore?",
    "534": "Riparare il protocollo",
    "535": "opzione 5",
    "536": "opzione 6",
    "537": "opzione 7",
    "538": "opzione 8",
    "539": "opzione 9",
    "540": "opzione 10",
    "541": "Email inviata.",
    "542": "L'invio dell'e-mail non è riuscito.",
    "543": "Appannamento del lubrificante",
    "544": "Regolato",
    "545": "Pulito",
    "546": "Sigillato",
    "547": "Accessori sostituiti",
    "548": "Commenti",
    "549": "Tutti gli elementi vengono consegnati in cantiere",
    "550": "Posizione degli elementi all'interno del progetto di costruzione",
    "551": "Invio del protocollo fallito!",
    "552": "Salvare le informazioni del protocollo",
    "553": "Informazioni salvate.",
    "554": "Impossibile salvare i dati.",
    "555": "Informazioni sulla finestra intelligente",
    "556": "Accettazione finestra intelligente",
    "557": "Numero di materiale dell'elettronica della finestra",
    "558": "Numero di serie dell'elettronica della finestra",
    "559": "Scansionare il QR-code (DMC) dal controllore master o inserirlo manualmente",
    "560": "Smart Window completamente configurata e funzionante",
    "561": "Tutti gli elementi visibili non presentano danni/difetti",
    "562": "È stato collegato un sistema di commutazione a 0-10V (0-100%)?",
    "563": "Luogo di installazione edificio",
    "564": "Avvio della scansione",
    "565": "Arresto della scansione",
    "566": "Elemento di posizione attualmente selezionato",
    "567": "È possibile selezionare al massimo 4 attributi contemporaneamente.",
    "568": "Pos del cliente",
    "569": "Data di produzione",
    "570": "Modifica ID finestra",
    "571": "ID finestra precedente",
    "572": "Nuovo ID finestra",
    "573": "Il numero Auftrag non esiste o il nuovo numero Auftrag è già in uso.",
    "574": "Codice a barre",
    "575": "Posizione ID",
    "576": "Tipo",
    "577": "ID elemento",
    "578": "Informazioni sull'elemento",
    "579": "Window.ID",
    "580": "Firmare nell'apposito spazio",
    "581": "Terminato",
    "582": "Firma",
    "583": "Nuova lingua",
    "584": "Lingua originale",
    "585": "Nome della lingua",
    "586": "Creare una nuova lingua",
    "587": "Aggiungi lingua",
    "588": "Aggiornamento",
    "589": "aggiornamento in corso",
    "590": "Disattivazione dell'azienda",
    "591": "Attivazione dell'azienda",
    "592": "Siete sicuri di voler disattivare questa azienda?",
    "593": "Siete sicuri di voler attivare questa azienda?",
    "594": "Disattivazione utente",
    "595": "Attivazione dell'utente",
    "596": "Siete sicuri di voler disattivare questo utente?",
    "597": "Siete sicuri di voler attivare questo utente?",
    "598": "ID Posizione",
    "599": "Tutti",
    "600": "Importazione/Esportazione",
    "601": "Scegliere un file in lingua .csv per l'importazione",
    "602": "Importazione",
    "603": "Esportazione",
    "604": "File",
    "605": "Traduttore",
    "606": "File dei codici a barre",
    "607": "Scegliere il percorso della cartella per i file dei codici a barre.",
    "608": "Window_ID",
    "609": "Sistema di profili ",
    "610": "clientPos",
    "611": "colore",
    "612": "Dimensione dell`elemento",
    "613": "Dimensione del vetro",
    "614": "Struttura in vetro",
    "615": "Informazioni sull´hardware",
    "616": "Data corrispondente",
    "617": "Data/ora di installazione",
    "618": "Dati GPS del luogo di installazione",
    "619": "Nr",
    "620": "Installazione ",
    "621": "Piano",
    "622": "Pos",
    "623": "ignora i caratteri iniziali",
    "624": "ignora i caratteri finali",
    "625": "Email - Attivazione dell`utente",
    "626": "Attivare le aziende",
    "627": "Periodo di prova scaduto",
    "628": "reinviare l`email di attivazione",
    "629": "Il rivenditore/la fabbrica esiste gia",
    "630": "L`utente e attivao",
    "631": "vai alla pagina di acceso",
    "632": "Dettagli dell`utente",
    "633": "Aggiungi utente collegato",
    "634": "Elimina collegamento utente",
    "635": "L´utente e gia collegato",
    "636": "Nome utente o password e vuoto",
    "637": "Utente non trovato",
    "638": "Invia protocollo",
    "639": "Assemblaggio e completato",
    "640": "Opzione 5",
    "641": "Opzione 6",
    "642": "Opzione 7",
    "643": "Opzione 8",
    "644": "Opzione 9",
    "645": "Opzione 10",
    "646": "Assemblatore",
    "647": "Tipo",
    "648": "Data",
    "649": "Descrizione",
    "650": "Manutenzione hardware",
    "651": "Set",
    "652": "Pulito",
    "653": "Sigillo",
    "654": "Accessori di ricambio",
    "655": "Commento",
    "656": "Connettore",
    "657": "Sistema di finestra",
    "658": "Pezzo",
    "659": "Nummero di progetto",
    "660": "ID ordine",
    "661": "Altezza",
    "662": "Larghezza",
    "663": "Informazioni sull`hardware",
    "664": "Larghezza x altezza del vetro",
    "665": "struttura del vetro",
    "666": "ID della finestra",
    "667": "ID electrica della finestra",
    "668": "ID del vetro",
    "669": "condizioni",
    "670": "aggiungi/rimuovi",
    "671": "e/o",
    "672": "gruppo",
    "673": "colonna",
    "674": "condizione",
    "675": "inserisci il numero del cliente",
    "676": "trduttore",
    "678": "controllo della versione dell´applicazione",
    "679": "L´aggiornamento potrebbe richiedere alcuni minuti per essere completato",
    "680": "in attesa della nuova versione",
    "681": "E disponibile una nuova versione dell´applicazione, sei sicuro di voler aggiornare?",
    "682": "L´applicazione e aggiornata, vuoi ripristinare la vecchia versione?",
    "683": "Ripristina la vecchia versione",
    "684": "inserisci il nome della stazione",
    "685": "riempitore di caratteri per il numero d´ordine",
    "686": "inserisci la lunghezza massima del numero d´ordine",
    "687": "Lunghezza massima del numero d´ordine",
    "688": "inserisci la lughezza massima del numero d´ordine",
    "689": "Percorso della cartella MDB",
    "690": "Inserisci il percoso del file .mdb (ultilizzato dall´importartore durante la scansione di file .mdb)",
    "691": "Percorso della cartella MDB",
    "692": "Inserisci il percorso del file .xml (ultilizzato dall´importare durante la scansione di file .xml)",
    "693": "Ignora i caratteri iniziali del QR-Code",
    "694": "Ignora i caratteri finali del QR-Code",
    "695": "Designazione",
    "696": "Lunghezza",
    "697": "Posizione aggiuntiva",
    "698": "Finestra intelligente senza danni",
    "699": "Finestra intelligente collegata",
    "700": "Orientamento della finestra",
    "701": "Nummero dell´elemto di posizione",
    "702": "Finestra intellegente configurata",
    "703": "Elemento senza danni",
    "704": "Numero del materiale",
    "705": "numero di serie",
    "706": "Nessuna immagine disponsible",
    "707": "Sito di costruzione pulito",
    "708": "Transcina e rilascia qui i file immagine/pdf",
    "709": "Caricamento file",
    "710": "tipo di file",
    "711": "ordine ( tutte le posizioni)",
    "712": "eliminato",
    "713": "Note di rilascio",
    "714": "Altezza (mm)",
    "715": "Larghezza (mm)",
    "716": "Non c´e pannelo per questa posizione",
    "717": "Articolo",
    "718": "Stazione di massa",
    "719": "Gamma di massa",
    "720": "il vetro e stato sostituito",
    "721": "gli accessori sono stati riparati",
    "722": "gli accessori sono stati riparati",
    "723": "L´anta é stata sostituita",
    "724": "La superficie é stata riparata",
    "725": "gli accessori sono stati riparati",
    "726": "La riparazione é completata",
    "727": "Conferma password",
    "728": "Password errata",
    "729": "Le password non corrispondono",
    "730": "La password non puó essere vuoto",
    "731": "Posizione",
    "732": "Cronologia",
    "733": "Valore U (W/m2K)",
    "734": "Vetro speciale",
    "735": "Nessuna Posizione",
    "736": "Avanzamento della produzione",
    "737": "Produzione completata",
    "738": "Avanzamento dell´installazione",
    "739": "Installazione eliminata",
    "740": "Stazione eliminata",
    "741": "Vuoi davvero eliminare questa stazione?",
    "742": "Aggiungi una nouva stazione",
    "743": "Elimina ordine",
    "744": "Eliminazione ordine",
    "745": "Sei sicuro di voler eliminare l´ordine?",
    "746": "Utente aggiunto",
    "747": "Utente modificato",
    "748": "Password dimenticata?",
    "749": "Il campo Password é obbligatorio",
    "750": "Il campo Nome utente é obbligatorio",
    "751": "Questo ruolo untente non é autorizzato all´accesso al dashboard",
    "752": "L´utente non é attivo",
    "753": "L´azienda utente non é attiva o il periodo di prova é terminato",
    "754": "Nome utente o password errati",
    "755": "Codice postale",
    "756": "Usa la rotella di scorrimento per ingrandire e rimpicciolire l´immagine",
    "757": "Reimposta password",
    "758": "L´email per il ripristino della password é stat invitia",
    "759": "La password é stata reimpostata",
    "760": "Email - Reimposta password",
    "761": "N. elemento",
    "762": "Numero d´ordine da",
    "763": "Inserisci il numero d´ordine da",
    "764": "Lunghezza del numero d´ordine",
    "765": "Intervallo d´ordine da",
    "766": "Inserisci l´intervallo d´ordine da",
    "767": "Inserisci la lunghezza dell´intervallo d´ordine",
    "768": "Pezzo d´ordine da",
    "769": "Inserisci la Lunghezza dell´intervallo d´ordine",
    "770": "Please insert order piece from",
    "772": "Lunghezza del pezzo d´ordine",
    "773": "Inserisci la lunghezza del pezzo d´ordine",
    "774": "Numero di posizione da",
    "775": "Inserisci il numero di posizione da",
    "776": "Lunghezza del numero di posizione da",
    "777": "Inserisci la lunghezza del numero di posizione",
    "778": "Pezzo di posizione da",
    "779": "Inserisci il pezzo di posizione da",
    "780": "lunghezza del pezzo di posizione",
    "781": "Inserisci la lunghezza del pezzo di posizione",
    "782": "Livello di sicurezza",
    "783": "caraterre di riempimento del codice a barre",
    "784": "Inserisci il carattere di riempimento del codice a barre",
    "785": "Torna ai progetti",
    "786": "(solo lettura)",
    "787": "accesso limitato",
    "788": "accesso solo in lettura",
    "789": "pagina di destinazione personale",
    "790": "Inserisci la stringa di connessione ",
    "791": "Stringa di connessione DB",
    "792": "Stringa di connessione STD DB",
    "793": "Stringa di connessione DB BWG",
    "794": "Connesione al Database",
    "795": "Chat",
    "796": "Messagi",
    "797": "Documento CE",
    "798": "Documento d´ordine",
    "799": "Ultima riparazione",
    "800": "Accettazione completata",
    "801": "Nuovo aggiornamento",
    "802": "Link esterno",
    "803": "Prefisso del link esterno",
    "804": "Riferimento al progetto",
    "805": "Intervallo di compiti",
    "806": "Utenti del dashboard",
    "807": "Utenti dell´app",
    "808": "Ultimo caricamento",
    "809": "Minuti",
    "810": "Versione del Matcher",
    "811": "Versione dell´importatore",
    "812": "Disconnetti utente",
    "813": "Utente disconnesso con successo",
    "814": "Codice a barre modificato",
    "815": "Informazioni aggiornate",
    "816": "Indicazioni",
    "817": "Install APP",
    "818": "Install l´APP Window.ID scansionando il codice QR",
    "819": "corrisponde",
    "820": "non corrisponde",
    "821": "Pianificatore",
    "822": "Numero di giorni",
    "823": "Impostazioni per il promemoria di manutenzione ",
    "824": "giorni",
    "825": "Prossima manutenzione",
    "826": "Modifica immagine",
    "827": "nessun filtro",
    "828": "agguingi ordine ",
    "829": "nuovo ordine  ",
    "830": "L´ordine esiste giá",
    "831": "La posizione esiste giá",
    "832": "Agguingi nuovo elemento",
    "833": "Agguingi nuovo campo ",
    "834": "Elimina posizione",
    "835": "Modifica posizione",
    "836": "Aggiungi  new posizione",
    "837": "sei sicuro di voler eliminare questa posizione?",
    "838": "Lingua predefinita",
    "839": "Descrizione richiesta ",
    "840": "Numero richiesto",
    "841": "Modifica ",
    "842": "Window .ID richiesto",
    "843": "Window.ID giá esistente",
    "844": "Non c´é elemento per questa pisizione",
    "845": "Non c´é campo di posizione",
    "846": "Aggiungi / Rimuovi elementi",
    "847": "Agguingi/ Rimuovi campi",
    "848": "Campo con questo id inesistente",
    "849": "Tipo di campo",
    "850": "Colore del campo",
    "851": "vetro",
    "852": "Cerca Window.ID",
    "853": "Cerca Window.ID",
    "854": "Copia da predefinito",
    "855": "Rack.ID",
    "856": "nome rack",
    "857": "Vuoto",
    "858": "gestione rack",
    "859": "Consegna completata senza danni",
    "860": "Consegna non completata",
    "861": "Consegna completata, ma alcuni elementi sono dannegiati",
    "862": "Finestre controllate in questo rack",
    "863": "agguingi nuovo rack",
    "864": "Modifica Rack",
    "865": "Rimuovi Rack",
    "866": "Sei sicuro di voler eliminare il rack?",
    "867": "nome Rack",
    "868": "Rack.ID",
    "869": "Collegamento rack",
    "870": "Rack in uso (chiuso)",
    "871": "Rack é aperto",
    "872": "Rack é libero",
    "873": "Stato predefinito",
    "874": "Elementi del Rack",
    "875": "Stazione del rack ",
    "876": "Rack",
    "877": "Rack con ID",
    "878": "é chiuso",
    "879": "Hai redistrato",
    "880": "Finestre ",
    "881": "é aperto dalla stazione ",
    "882": "Impossibile aprire il rack",
    "883": "chiudere il rack",
    "884": "Scansiona Window.ID",
    "885": "Scansiona Rack.ID",
    "886": "Scansiona Rack.ID per iniziare",
    "887": "Il Rack non é disponibile",
    "888": "Errore",
    "890": "Window.ID non é scansionato",
    "891": "Questo elemento é giá scansionato",
    "892": "Questo elemento é scansionato nel rack ",
    "893": "Sei sicuro di voler caricare il fine?",
    "894": "svuotare il rack?",
    "895": "Cambia fotocamera",
    "896": "scansiona precedente",
    "897": "Scansiona nuovo ",
    "898": "È richiesto un rivenditore ",
    "899": "Aggiungi immagine ",
    "900": "Vai alla pagina   ",
    "901": "Accettazione generale",
    "902": "Alcuni elementi di posizione hanno giá informazioni di accettazione inserite. Accettare tutto sovrascriverá esistenti",
    "903": "Impersonazione aziendale",
    "904": "Modifica pagina di destinazione",
    "905": "Informazioni sul rack",
    "906": "Rack vuoto",
    "907": "Il rack è vuoto",
    "908": "Stato del rack",
    "909": "Chiuso",
    "910": "Pieno",
    "911": "In uso",
    "912": "Perno del rack",
    "913": "Inserire il perno del rack",
    "914": "Perno sbagliato",
    "915": "Not imported",
    "916": "Standort / Adresse",
    "917": "Auftragnehmer / Firma - Adresse",
    "918": "Element",
    "919": "Acceptance points",
    "920": "own WID logo/colors",
    "921": "Appearance",
    "922": "Monteur signature",
    "923": "Customer signature",
    "924": "Separator for auto upload (Documents)",
    "925": "Please insert global API Key",
    "926": "Maintenance report",
    "927": "Repair report",
    "928": "Please insert char separator for documents",
    "929": "Station not found in database",
    "930": "Barcode is empty",
    "931": "QRCode is in use",
    "932": "ERROR / WARNING",
    "933": "Matching did not work!",
    "934": "Barcode",
    "935": "Window-ID",
    "936": "Error message",
    "937": "Scan to confirm",
    "938": "QR-Code is empty",
    "939": "Dettagli tecnici",
    "940": "Delivery",
    "941": "Rack.ID Station",
    "942": "Ignora i caratteri iniziali del Rack.ID",
    "943": "Ignora i caratteri finali del Rack.ID",
    "944": "Rack management",
    "945": "Colour order",
    "946": "Colour Inside / Colour Outside",
    "947": "Colour Outside / Colour Inside",
    "948": "Fabbrica",
    "949": "Factory is required",
    "950": "Matcher - actual version",
    "951": "Assembly incomplete",
    "952": "Scanner settings",
    "953": "Camera selection",
    "954": "Camera",
    "955": "No cameras found.",
    "956": "Press RFID START to start scanner.",
    "957": "Stop preview",
    "958": "Start preview"
};

export default it;