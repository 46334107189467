const ro = {
    "1": "Da",
    "2": "Nu",
    "3": "30-Zile versiune test",
    "4": "O sesizare a fost raportata prin intermediul aplicatiei",
    "5": "Acceptare",
    "6": "Data acceptarii",
    "7": "Actiune",
    "8": "Activ",
    "9": "Activitati",
    "10": "Adaugare",
    "11": "Adaugati montator",
    "12": "Adauga firma",
    "13": "Adauga dealer/montator",
    "14": "Adauga producator",
    "15": "Adauga cont nou pentru management",
    "16": "Adauga utilizator",
    "17": "Camp suplimentar",
    "18": "Editare supliementara camp nume",
    "19": "Mentiuni",
    "20": "Informatii suplimentare",
    "21": "Produse suplimentare",
    "22": "Reglaj",
    "23": "Admin",
    "24": "Administrare proiect",
    "25": "Toate elementele sunt livrate la santier",
    "26": "Toate pozitiile sunt montate",
    "27": "Toate drepturile rezervate",
    "28": "Sigur doriti sa stergeti aceasta firma?",
    "29": "Sigur doriti sa stergeti aceast utilizator?",
    "30": "Montat",
    "31": "Montator",
    "32": "Montatori",
    "33": "Montaj",
    "34": "Montaj finalizat",
    "35": "Data montajului",
    "36": "Proiect",
    "37": "Instructiuni de intretinere",
    "38": "Detalii certificat",
    "39": "Certificat (-e)",
    "40": "Curatare",
    "41": "sterge filtru",
    "42": "Inchide",
    "43": "Culoare",
    "44": "Culoare / decor",
    "45": "Comision",
    "46": "Firma",
    "47": "Pagina producatorului",
    "48": "Informatii firma",
    "49": "Expunere (puncte cardinale)",
    "50": "Reclamatii",
    "51": "Comanda completa",
    "52": "Efectuat",
    "53": "Fereasra conectata",
    "54": "Santier curat",
    "55": "Data crearii",
    "56": "Client",
    "57": "Numar client",
    "58": "Referinta element",
    "59": "Data",
    "60": "Interval data",
    "61": "Dealer",
    "62": "Sterge firma",
    "63": "Sterge producator",
    "64": "Sterge utilizator",
    "65": "Data livrare",
    "66": "Livrare fara deteriorare",
    "67": "Descriere",
    "68": "Desemnarea locului de montaj",
    "69": "Afiseaza _MENIU_ inregistrari pe pagina",
    "70": "Producator",
    "71": "Documente",
    "72": "Est",
    "73": "Editeaza montator",
    "74": "Editare firma",
    "75": "Editare producator",
    "76": "Editeaza contul de management",
    "77": "Editare utilizator",
    "78": "Latimea x Ianltimea elementului in mm",
    "79": "Elementul cu acest ID nu exista",
    "80": "Elemente",
    "81": "Datele de acces la email au fost salvate",
    "82": "Introduceti culoare / decor",
    "83": "Introduceti comision",
    "84": "Introduceti nume firma",
    "85": "Introduceti e-mail",
    "86": "Introduceti extensie",
    "87": "Introduceti inaltimea",
    "88": "Introduceti adresa IP",
    "89": "Introduceti nume",
    "90": "Introduceti numar",
    "91": "Introduceti numarul pozitiilor",
    "92": "Introduceti parola",
    "93": "Introduceti numar de telefon",
    "94": "Introduceti numar de telefon",
    "95": "Introduceti cod postal",
    "96": "Introduceti cantitatea",
    "97": "Introduceti strada",
    "98": "Introduceti prenumele",
    "99": "Introduceti numele utilizatorului",
    "100": "Introduceti codul fiscal",
    "101": "Introduceti pagina web",
    "102": "Introduceti latimea",
    "103": "Eroare la salvare",
    "104": "Eroare la salvarea datelor de acces la e-mail ",
    "105": "Inlocuire accesorii",
    "106": "Expirata pe",
    "107": "Expira la",
    "108": "Extensie",
    "109": "Producator",
    "110": "Producator",
    "111": "Filtru",
    "112": "filtrata din _MAX_ inregistrari totale",
    "113": "Primul",
    "114": "Anuleaza",
    "115": "Date feronerie",
    "116": "Plasa insecte",
    "117": "Amprenta CO2",
    "118": "Text aditional",
    "119": "Din aplicatie",
    "120": "Din vizualizarea managementului",
    "121": "Solutie de curatare sticla",
    "122": "Inlocuire sticla",
    "123": "Cofiguratie sticla",
    "124": "Sticla latime x inaltime in mm",
    "125": "Intretinere feronerie",
    "126": "Inlocuire feronerie",
    "127": "Inaltime",
    "128": "Instructiuni de curatare",
    "129": "Imagine",
    "130": "Informatii despre element",
    "131": "Informatiile sunt restrictionate",
    "132": "Intrare",
    "134": "Locatia montajului",
    "135": "Introdu/scaneaza cod de bare",
    "136": "Adresa IP",
    "137": "este necesara",
    "138": "Limba",
    "139": "Ultimul",
    "140": "Ultima intretinere",
    "141": "Ultima sesizare",
    "142": "Ultima revizie",
    "143": "Link la articol",
    "144": "Incarcare",
    "145": "Amplasarea elementelor in cadrul proiectului de constructie",
    "146": "Delogare",
    "147": "Date de acces la e-mail",
    "148": "Intretinere",
    "149": "schimbare manuala prin",
    "150": "Produse",
    "151": "Master control forma",
    "152": "Master control nr. materialului",
    "153": "Master control nr. serial",
    "154": "Data procesarii",
    "155": "Vizualizare meniu",
    "156": "Confirmare automata cod de bare",
    "157": "Mai multe informatii despre sustenabilitatea REHAU",
    "158": "Noua perioada de testare",
    "159": "Urmatorul",
    "160": "Nicio deteriorare la element",
    "161": "Nicio deteriorare la sticla",
    "162": "Nu s-au gasit inregistrari corespunzatoare",
    "163": "Niciunul din elemente nu este montat",
    "164": "Nord",
    "165": "Neasamblat",
    "166": "Nu s-a gasit nimic - scuze",
    "167": "Numar",
    "168": "Numarul de elemente",
    "169": "Diagrama",
    "170": "Numarul de pozitii",
    "171": "la",
    "172": "Data comenzii",
    "173": "Numar de comanda",
    "174": "Evolutia comenzii",
    "175": "Iesire",
    "176": "Parola",
    "177": "Telefon",
    "178": "Localitatea",
    "179": "Solutie de curatare PVC",
    "180": "Pos. document",
    "181": "Detalii pozitie",
    "182": "Pozitia cu acest ID nu exista",
    "183": "Pozitii",
    "184": "Anterior",
    "185": "Acreditarile utilizatorului",
    "186": "Tipareste datele de acces",
    "187": "Prelucrare",
    "188": "Producator",
    "189": "Sistem de profile",
    "190": "Proiect",
    "191": "Nr. Proiectului",
    "192": "Management proiect",
    "193": "Cantitate",
    "194": "Reclamatie",
    "195": "Selecteaza statie",
    "196": "Continut reciclat",
    "197": "Reparatie",
    "198": "Accesorii de reparat",
    "199": "Suprafete de reparat",
    "200": "Raportat din App",
    "201": "Raportat din vizualizarea managementului",
    "202": "Resetati perioada de proba",
    "203": "Rezultat",
    "204": "Calitate",
    "205": "Salvare",
    "206": "Salvat cu succes",
    "207": "Curatare suprafata",
    "208": "Cauta",
    "209": "Nivel de securitate",
    "210": "Transmite datele catre REHAU",
    "211": "Service",
    "212": "Service & Vanzari",
    "213": "Administrator",
    "214": "Setari",
    "215": "Este necesara o acceptare manuala",
    "216": "Se afiseaza de la _START_ la _FIANL_ din _TOTAL_ inregistrari",
    "217": "Se afiseaza de la 0 la 0 din 0 inregistrari",
    "218": "Rulouri",
    "219": "Logare",
    "220": "Logare, pentru a incepe",
    "221": "Informatii Smart Glass",
    "222": "Smart Window",
    "223": "Unele elemente sunt montate",
    "224": "Unele elemente nu sunt alocate",
    "225": "Unele pozitii necesita montarea",
    "226": "Sud",
    "227": "Statistica",
    "228": "Str.",
    "229": "Prenume",
    "230": "Sustenabilitate si energie",
    "231": "Telefon",
    "232": "Perioada de proba resetata cu succes",
    "233": "Perioada de testare",
    "234": "Proprietatile au fost pastrate",
    "235": "Sunt anumite reclamatii",
    "236": "Nu exista firma cu acest ID",
    "237": "Nu exista informati despre acest element",
    "238": "Aceasta firma exista deja",
    "239": "Titlu",
    "240": "la",
    "241": "Acceptare totala",
    "242": "Numarul total",
    "243": "Necunoscut",
    "244": "Incarcare sigla",
    "245": "Administare utilizator",
    "246": "Nume utilizator",
    "247": "Acest nume de utilizator este deja alocat",
    "248": "Utilizator",
    "249": "Uw - coeficient fereastra",
    "250": "Uf - coeficient tamplarie",
    "251": "Ug - coeficient sticla",
    "252": "W.ID atribuit",
    "253": "Avertisment: Nu corupeti Window.ID",
    "254": "Bun venit la Window.ID",
    "255": "Vest",
    "256": "Latime",
    "257": "Latime x Inaltime in mm",
    "258": "Suprafata fereastra de la ",
    "259": "Suprafata fereastra in",
    "260": "Numarul electronic al ferestrei",
    "261": "Numarul serial al ferestrei",
    "262": "Locatia montajului ferestrei",
    "263": "Sistemul de ferestre",
    "264": "Tipul de fereastra",
    "265": "Inlocuire canat",
    "266": "Esti sigur?",
    "267": "Eliberati filtrul",
    "268": "Editeaza",
    "269": "Exportati CSV",
    "270": "Citire cod de bare",
    "271": "ID-ul sticlei",
    "272": "Informatii",
    "273": "Legatura",
    "274": "Utilizatori conectati",
    "275": "Email",
    "276": "Setari de e-mail",
    "277": "Perioada de testare a e-mailului",
    "278": "Calitate service",
    "279": "Compania de service",
    "280": "Telefon service",
    "281": "E-mail de service",
    "282": "Stare",
    "283": "Setari statie",
    "284": "Subiect",
    "285": "Tel.",
    "286": "Utilizator",
    "287": "Site web",
    "288": "ID fereastra",
    "289": "Sistemul de ferestre",
    "290": "Detalii client",
    "291": "Nume",
    "292": "Configuratie sticla",
    "293": "Detalii feronerie",
    "294": "Calitate montator",
    "295": "Compania de montaj",
    "296": "Telefon montator",
    "297": "E-mail montator",
    "298": "URL",
    "299": "tara",
    "300": "Va rugam sa adaugati un utilizator pentru aceasta companie",
    "301": "Meniu suplimentar",
    "302": "Tablou de bord",
    "303": "Aplicatie",
    "304": "Format pagina",
    "305": "Administrarea utilizatorilor",
    "306": "Confirmare",
    "307": "Corpul",
    "308": "Atasament",
    "309": "Id",
    "310": "Pozitia",
    "311": "Avertisment",
    "312": "W.ID",
    "314": "Dealeri",
    "315": "Activare",
    "316": "Dezactivare",
    "317": "Firma",
    "318": "Ne pare rau",
    "319": "Administrator",
    "320": "Utilizator",
    "321": "Cod postal",
    "322": "Logo",
    "323": "Imagine de fundal",
    "324": "Cod fiscal",
    "325": "Document CE",
    "326": "Setari aplicatie",
    "327": "Automat",
    "328": "Manual",
    "329": "Cautare",
    "330": "Incarcare",
    "331": "Incarcare automata documente",
    "332": "Locatie director",
    "333": "Alege locatie director pentru incarcare automata documente",
    "334": "Alege fisier .zip care contine fisiere PPS, XML, client XML si pozitie ",
    "335": "Statie",
    "336": "Scanare manuala",
    "337": "Statie sticla Smart",
    "338": "Tranfer informatii",
    "339": "Numar maxim de pozitii in cronologie",
    "340": "QR cod de la",
    "341": "QR cod pana la",
    "342": "QR cod prefix",
    "343": "QR cod lungime",
    "344": "Codul de bare este invalid",
    "345": "Cheie Api",
    "346": "Introdu cod QR",
    "347": "Nume statie",
    "348": "Nici un fisier selectat",
    "349": "Fisier incarcat",
    "350": "Eroare de incarcare a fisierului",
    "351": "Scanarea manuala este activata, codul QR nu va fi verificat!",
    "352": "Va rog introduceti cod QR",
    "353": "Codul de bare este invalid",
    "354": "Codul de bare a fost incarcat",
    "356": "Fara date",
    "357": "Conexiune esuata",
    "358": "Istoric",
    "359": "Codul de bare este utilizat",
    "360": "Eroare la corelarea codului de bare",
    "361": "Manual",
    "362": "de la",
    "363": "pana la",
    "364": "Lungimea prefixului codului QR trebuie sa fie mai mica decat lungimea codului QR",
    "365": "Intervalul codurilor de bare este invalid",
    "366": "Numar invalid",
    "367": "Utilizat anterior",
    "368": "Utilizat astazi",
    "369": "Liber",
    "370": "Utilizat pana astazi",
    "371": "Utilizarea etichetei Window.ID in cadrul statiei selectate",
    "372": "Traduceri",
    "373": "Editati traducerea",
    "374": "Tasta",
    "375": "Engleza",
    "376": "Valoare",
    "377": "W.ID de atribuit",
    "378": "Pozitiile ferestrelor/usilor in proiect",
    "379": "Service reparatii / Dovada reperatiei",
    "380": "Mentenanta & Istoric",
    "381": "Protocol de asamblare",
    "382": "Modificari",
    "383": "Schimbare sticla",
    "384": "Schimbare canat",
    "386": "Text aditional",
    "387": "Proprietatile au fost pastrate",
    "388": "Proprietati noi",
    "389": "Raport de intretinere",
    "390": "Gresare feronerie ",
    "391": "Programati mentenanta",
    "392": "Intregul proiect",
    "393": "Informatii fereastra",
    "394": "Informatii montaj",
    "395": "Culoare",
    "396": "Latimea x Ianltimea elementului in mm",
    "397": "Sticla latime x inaltime in mm",
    "398": "Informatii feronerie",
    "399": "Numele montatorului",
    "400": "Data / ora montajului",
    "401": "Locul montajului (GPS)",
    "402": "ID dispozitiv JustSmart",
    "403": "trimite",
    "404": "Pozitie filtru",
    "405": "Niciuna",
    "406": "Usa de terasa",
    "407": "Bucatarie",
    "408": "Baie",
    "409": "Camera",
    "410": "Pod",
    "411": "Montaj",
    "412": "Etaj",
    "413": "Atasare poza",
    "414": "Semnatura",
    "415": "Desen",
    "416": "Element fara deteriorari",
    "417": "Sticla fara deteriorari",
    "418": "Deschide camera",
    "419": "Incarcati o imagine",
    "420": "Instalare fereastra si alocare",
    "421": "Raport de acceptare",
    "422": "Trimite",
    "423": "Intreg proiectul",
    "424": "Tip montaj",
    "425": "Explicit pentru element",
    "426": "Intraga lucrare finalizata",
    "427": "Protocol de montaj individual pe ferestre ",
    "428": "Exista probleme",
    "429": "Solicitare ajutor",
    "430": "Mesaj",
    "431": "Trimite",
    "432": "Urmatorul element",
    "433": "Lista cu toate elementele",
    "434": "Graficul de constructie",
    "435": "via Numar de comanda",
    "436": "via scanare cod QR",
    "437": "Arata singular",
    "438": "sau",
    "439": "Numar cod de bare",
    "440": "Arata informatii",
    "441": "Reparatii / Reclamatii",
    "442": "Persoana de contact",
    "443": "Mentiuni",
    "444": "Scaneaza alt cod QR",
    "445": "Urmatoarea revizie",
    "446": "Furnizorul tamplariei",
    "447": "Caracteristici",
    "448": "Valoarea izolatiei fonice",
    "449": "Uf",
    "450": "Solutii de modernizare / Optiuni de extindere",
    "451": "Contact",
    "452": "ID-ul ferestri dumneavoastra",
    "453": "Ce doriti sa faceti?",
    "454": "Intretinerea regulata a ferestrelor prelungeste durata de viata si confortul",
    "455": "Solicitati programarea pentru intretinere prin formularul de contact",
    "456": "Solicitati programarea pentru intretinere telefonic",
    "457": "catre montatorul de ferestre",
    "458": "Confirm prin prezenta termenii si conditiile generale si regulamantul GDPR",
    "459": "Preocuparea dumneavoastra",
    "461": "Contacteaza prin …",
    "462": "Geam spart",
    "463": "Fereastra nu se inchide corespunzator",
    "464": "Fereastra este blocata",
    "465": "Fereastra este deteriorata",
    "466": "Alte accesorii",
    "467": "Ferestrele si usile tale au fost la",
    "468": "montate de catre firma",
    "469": "producatorul ferestrelor",
    "470": "Evalueaza-ti ferestrele si firma de montaj acum",
    "471": "Cat de multumit esti de firma de service?",
    "472": "Produsele livrate sunt ok?",
    "473": "Cum iti plac ferestrele tale?",
    "474": "Au existat probleme in derularea procesului?",
    "475": "Cu ce ai fost multumit?",
    "476": "Ce ati imbunatatii?",
    "477": "Instructiuni de montaj",
    "478": "Pozitia ferestrelor si usilor",
    "479": "Deschide raport de montaj",
    "480": "Lista completa a elementelor comenzii",
    "481": "Lista elementelor dupa numarul de comanda / QR",
    "482": "Trimite raport proiect",
    "483": "Detalii fereastra",
    "484": "Detalii individuale",
    "485": "Numarul proiectului de executie",
    "486": "Ferestra",
    "487": "Pozitie fereastra",
    "488": "Service reparatii",
    "489": "Detalii mentenanta",
    "490": "Client",
    "491": "Formular de contact",
    "492": "Gasiti un montator REHAU in apropiere",
    "493": "Categorie",
    "494": "Scanare cod QR",
    "495": "Persoana de contact",
    "496": "Actualizare",
    "497": "Filtru",
    "498": "Montajul este finalizat",
    "499": "Montaj finalizat?",
    "500": "Livrare fara deteriorare",
    "501": "Salvare ca PDF",
    "502": "Preia imagine",
    "503": "Adresa de e-mail invalida",
    "504": "Instaleaza app",
    "505": "Localitatea",
    "506": "Lista de proiecte",
    "507": "Incarca proiectul selectat?",
    "508": "Numar de comanda / cod de bare gresit",
    "509": "Fereastra unica",
    "510": "Introduceti modificari",
    "511": "Trimite raport prin e-mail",
    "512": "Selectati 4 optiuni",
    "513": "Scanati codul QR pentru a afla unde trebuie montat elementul",
    "514": "Salveaza datele",
    "515": "Arata",
    "516": "Rezultate",
    "517": "Pagina",
    "518": "de la",
    "519": "Data de la ",
    "520": "Data pana la",
    "521": "Deschide",
    "522": "Marime",
    "523": "Montat la",
    "524": "ok",
    "525": "Informatii",
    "526": "S-a incarcat o noua comanda",
    "527": "ID montator",
    "528": "Logare",
    "529": "Confirm prin prezenta termenii si conditiile generale si reglentarile GDPR",
    "530": "Delogare",
    "531": "Scaner",
    "532": "Trimite protocolul de acceptare",
    "533": "Raportul de acceptare pentru aceasta comanda sa fie transmis administratorului?",
    "534": "Raport de remediere",
    "535": "optiunea 5",
    "536": "optiunea 6",
    "537": "optiunea 7",
    "538": "optiunea 8",
    "539": "optiunea 9",
    "540": "optiunea 10",
    "541": "Email transmis",
    "542": "Trmiterea e-mailului a esuat!",
    "543": "Mentenata feronerie",
    "544": "Reglaj",
    "545": "Curatare",
    "546": "Etansare",
    "547": "Inlocuire accesorii",
    "548": "Observatii",
    "549": "Toate elementele sunt livrate la santier",
    "550": "Amplasarea elementelor in cadrul proiectului de constructie",
    "551": "Transmiterea protocolului a esuat!",
    "552": "Salvare informatii protocol",
    "553": "Date salvate",
    "554": "Nu s-au salvat datele",
    "555": "Informatii Smart Window",
    "556": "Accesptare Smart Window",
    "557": "Numarul electronic serial al materialului",
    "558": "Numarul electronic serial al ferestrei",
    "559": "Scanati codul QR (DMC) de la controler sau introduceti-l manual",
    "560": "Fereastra Smart complet configurata si functionala",
    "561": "Toate elementele vizibile fara defecte",
    "562": "A fost conectat un sistem de comutare 0-10V (0-100%)?",
    "563": "Locatia cladirii",
    "564": "Start scanare",
    "565": "Stop scanare",
    "566": "Elementele selectate",
    "567": "Pot fi selectate maximum 4 atribute in acelasi timp",
    "568": "Pozitie client",
    "569": "Data productiei",
    "570": "Schimba Window.ID",
    "571": "Window.ID anterior",
    "572": "Window.ID nou",
    "573": "Numarul comenzii nu exista sau noul numar de comanda este deja utilizat",
    "574": "ID fereastra",
    "575": "Pozitie ID",
    "576": "Tip",
    "577": "ID element",
    "578": "Informatii element",
    "579": "Window.ID",
    "580": "Va rugam sa va conectati in spatiul oferit",
    "581": "Finalizat",
    "582": "Semneaza",
    "583": "Limba noua",
    "584": "Limba originala",
    "585": "Limba",
    "586": "Creati o noua limba",
    "587": "Adaugati o limba",
    "588": "Actualizare",
    "589": "Actualizare in curs",
    "590": "Dezactivare companie",
    "591": "Activare companiei",
    "592": "Sunteti sigur ca doriti sa dezactivati aceasta companie?",
    "593": "Sunteti sigur ca doriti sa activati aceasta companie?",
    "594": "Dezactivare utilizator",
    "595": "Activare utilizator",
    "596": "Sunteti sigur ca doriti sa dezactivati acest utilizator?",
    "597": "Sunteti sigur ca doriti sa activati acest utilizator?",
    "598": "ID Pozitie",
    "599": "Toate",
    "600": "Import/Export",
    "601": "Va rugam sa alegeti limba fisierul .csv pentru import",
    "602": "Import",
    "603": "Export",
    "604": "Fisier",
    "605": "Traducator",
    "606": "Fisiere cu coduri de bare",
    "607": "Va rugam sa alegeti calea dosarului pentru fisierele de coduri de bare.",
    "608": "Window_ID",
    "609": "Sistem de profile",
    "610": "Pozitia clientului",
    "611": "Culoare",
    "612": "Dimensiunea elementului",
    "613": "Dimensiunea sticlei",
    "614": "Structura sticlei",
    "615": "Informatii feronerie",
    "616": "Potrivire Data",
    "617": "Data/ora instalarii",
    "618": "Date GPS locatia de instalare",
    "619": "Nr:",
    "620": "Instalare:",
    "621": "Etaj:",
    "622": "Pos:",
    "623": "Ignora caracterul (caracterele) initial€",
    "624": "Ignora caracterul (caracterele) final(e)",
    "625": "Email - Activare utilizator",
    "626": "Activare companii",
    "627": "Perioda demo expirata",
    "628": "Reitransmite e-mailul de activare",
    "629": "Dealer/Producator exista deja",
    "630": "Utilizatorul este activat",
    "631": "Mergeti la pagina de autentificare",
    "632": "Detalii utilizator",
    "633": "Adaugati un utilizator legat",
    "634": "Sterge conexiunea cu utilizatorul",
    "635": "Utilizatorul este deja conectat",
    "636": "Numele de utilizator sau parola lipseste",
    "637": "Utilizatorul nu a fost gasit",
    "638": "Trimiteti protocolul",
    "639": "Intrebari finalizare asamblare",
    "640": "Optiunea 5",
    "641": "Optiunea 6",
    "642": "Optiunea 7",
    "643": "Optiunea 8",
    "644": "Optiunea 9",
    "645": "Optiunea 10",
    "646": "Montator",
    "647": "Tip",
    "648": "Data",
    "649": "Descriere",
    "650": "Intretinerea hardware-ului",
    "651": "Setati",
    "652": "Curatare",
    "653": "Etansare",
    "654": "Accesorii de inlocuit",
    "655": "Mentiuni",
    "656": "Conector",
    "657": "Sistem de ferestre",
    "658": "Bucata",
    "659": "Numarul proiectului",
    "660": "ID-ul comenzii",
    "661": "Inaltime",
    "662": "Latime",
    "663": "Informatii despre hardware",
    "664": "Latimea x Inaltimea sticlei",
    "665": "Structura sticlei",
    "666": "Window.ID",
    "667": "ID-ul electric al ferestrei",
    "668": "ID sticla",
    "669": "Conditii",
    "670": "Adaugare/eliminare",
    "671": "si/sau",
    "672": "Grupul",
    "673": "Coloana",
    "674": "Conditie",
    "675": "Introduceti numarul de client",
    "676": "Traducator",
    "678": "Controlul versiunii aplicatiei",
    "679": "Actualizarea ar putea dura cateva minute pentru a se finaliza",
    "680": "In asteptarea noii versiuni",
    "681": "Exista o noua versiune a aplicatiei, sunteti sigur ca doriti sa faceti actualizarea?",
    "682": "Aplicatia este actualizata, doriti sa restaurati versiunea veche?",
    "683": "Restaurati versiunea veche",
    "684": "Introduceti numele statiei",
    "685": "Numarul de comanda introducere caractere",
    "686": "Va rugam sa introduceti numarul de comanda",
    "687": "Lungimea maxima a numarului de comanda",
    "688": "Va rugam sa introduceti lungimea maxima a numarului de comanda",
    "689": "Calea dosarului .MDB",
    "690": "Va rugam sa introduceti calea fisierului .mdb (utilizata de importator la scanarea fisierelor .mdb)",
    "691": "Calea dosarului .XML",
    "692": "Va rugam sa introduceti calea fisierului .XML (utilizata de importator la scanarea fisierelor .xml)",
    "693": "Ignora caracterul (caracterele) de inceput al codului QR",
    "694": "Ignora caracterul (caracterele) final(e) al(e) codului QR",
    "695": "Denumire",
    "696": "Lungime",
    "697": "Pozitie suplimentara",
    "698": "Fereastra inteligenta fara daune",
    "699": "Fereastra inteligenta conectata",
    "700": "Orientarea ferestrei",
    "701": "Numarul pozitiei elementului",
    "702": "Fereastra inteligenta configurata",
    "703": "Element fara daune",
    "704": "Numarul materialului",
    "705": "Numarul de serie",
    "706": "Nicio imagine disponibila",
    "707": "Santierul de constructii curat",
    "708": "Trageti si plasati aici fisierele imagine/pdf",
    "709": "Incarcare fisier",
    "710": "Tipul de fisier",
    "711": "Comanda (toate pozitiile)",
    "712": "Sterse",
    "713": "Note de lansare",
    "714": "Inaltime (mm)",
    "715": "Latime (mm)",
    "716": "Nu exista un panou pentru aceasta pozitie",
    "717": "Articol",
    "718": "Statie de incarcare",
    "719": "Intervalul de incarcare",
    "720": "Window.ID (ultimul)",
    "721": "Geamul a fost inlocuit",
    "722": "Accesoriile au fost reparate",
    "723": "Fereastra a fost inlocuita",
    "724": "Suprafata a fost reparata",
    "725": "Accesoriile au fost reparate",
    "726": "Reparatia este finalizata",
    "727": "Confirmati parola",
    "728": "Parola gresita",
    "729": "Parola nu se potriveste",
    "730": "Parola nu poate fi goala",
    "731": "Locatie",
    "732": "Cronologie",
    "733": "Valoare U (W/m²K)",
    "734": "Sticla speciala",
    "735": "Fara pozitie",
    "736": "Progresul productiei",
    "737": "Productie finalizata",
    "738": "Progresul instalarii",
    "739": "Instalare finalizata",
    "740": "Statie stearsa",
    "741": "Chiar doriti sa stergeti aceasta statie?",
    "742": "Adaugati o noua statie",
    "743": "Stergeti comanda",
    "744": "Deselctati comanda",
    "745": "Sunteti sigur ca doriti sa stergeti comanda?",
    "746": "Utilizator adaugat",
    "747": "Utilizator modificat",
    "748": "Ati uitat parola?",
    "749": "Campul Parola este obligatoriu",
    "750": "Campul Nume utilizator este obligatoriu",
    "751": "Acest utilizator nu este autorizat pentru conectarea la tabloul de bord",
    "752": "Utilizatorul nu este activ",
    "753": "Compania utilizatorului nu este activa sau perioada de testare s-a incheiat",
    "754": "Numele de utilizator sau parola este incorecta",
    "755": "Cod postal",
    "756": "Utilizati rotita de derulare pentru a mari sau micsora imaginea",
    "757": "Resetati parola",
    "758": "A fost trimis un e-mail pentru resetarea parolei",
    "759": "Parola a fost resetata",
    "760": "Email - Resetare parola",
    "761": "Nr. element",
    "762": "Numarul de ordine de la",
    "763": "Va rugam sa introduceti numarul de comanda de la",
    "764": "Lungimea numarului de comanda",
    "765": "Va rugam sa introduceti lungimea numarului de comanda",
    "766": "Intervalul de comanda de la",
    "767": "Va rugam sa introduceti intervalul de comanda de la",
    "768": "Lungimea intervalului de comanda",
    "769": "Va rugam sa introduceti lungimea intervalului de comanda",
    "770": "Please insert order piece from",
    "772": "Lungimea piesei de comanda",
    "773": "Va rugam sa introduceti lungimea piesei de comanda",
    "774": "Numarul de pozitie de la",
    "775": "Va rugam sa introduceti numarul de pozitie de la",
    "776": "Lungimea numarului de pozitie",
    "777": "Va rugam sa introduceti lungimea numarului de pozitie",
    "778": "Pozitia piesei de la",
    "779": "Va rugam sa introduceti pozitia piesei de la",
    "780": "Lungimea piesei de pozitie",
    "781": "Va rugam sa introduceti lungimea piesei de pozitie",
    "782": "Nivelul de securitate",
    "783": "Caracter de completare a codului de bare",
    "784": "Va rugam sa introduceti caracterele codului de bare",
    "785": "Inapoi la proiecte",
    "786": "(numai pentru citire)",
    "787": "acces limitat",
    "788": "acces numai pentru citire",
    "789": "pagina de aterizare proprie",
    "790": "Va rugam sa introduceti codul de conexiune",
    "791": "codul de conexiune DB",
    "792": "codul de conexiune DB STD",
    "793": "codul de conexiune DB BWG",
    "794": "Conexiune la baza de date",
    "795": "Chat",
    "796": "Mesaje",
    "797": "Document CE",
    "798": "Document de comanda",
    "799": "Ultima reparatie",
    "800": "Acceptare finalizata",
    "801": "O noua actualizare este disponibila, va rugam sa reporniti aplicatia",
    "802": "Link extern",
    "803": "Prefixul linkului extern",
    "804": "Referinta proiect",
    "805": "Interval de activitate",
    "806": "Utilizator Dashbord",
    "807": "Utilizator App",
    "808": "Ultima incarcare",
    "809": "minute",
    "810": "Versiune Matcher",
    "811": "Versiune de import",
    "812": "Delogare user",
    "813": "User deogat cu suuuces",
    "814": "Cod de bare schimbat",
    "815": "Informatii actualizate",
    "816": "Directie",
    "817": "Instalare APP",
    "818": "Instalare Window.ID APP prin scanare cod QR",
    "819": "imperecheat",
    "820": "neimperecheat",
    "821": "Planner",
    "822": "numar de zile",
    "823": "Setare memento de intretinere",
    "824": "zile",
    "825": "Urmatoarea mentenanta",
    "826": "Ediatre imagine",
    "827": "Fara filtru",
    "828": "Adauga comanda",
    "829": "New order",
    "830": "Order already exits",
    "831": "Position already exists",
    "832": "Add new element",
    "833": "Add new field",
    "834": "Delete position",
    "835": "Edit position",
    "836": "Add new position",
    "837": "Are you sure you want to delete this position?",
    "838": "Default language",
    "839": "Description is required",
    "840": "Number is required",
    "841": "Edit order",
    "842": "Window.ID is required",
    "843": "Window.ID already exists",
    "844": "There is no element for this position",
    "845": "There is no position field",
    "846": "Add/Remove elements",
    "847": "Add/Remove fields",
    "848": "Field with this id does not exist",
    "849": "Field type",
    "850": "Field color",
    "851": "Glass",
    "852": "Search by Window ID",
    "853": "Go to position element",
    "854": "Copy from default",
    "855": "Rack.ID",
    "856": "Rack name",
    "857": "Empty",
    "858": "Rack management",
    "859": "Delivery completed without damage.",
    "860": "Delivery not completed.",
    "861": "Delivery completed, but some elements are damaged.",
    "862": "Checked in windows in this rack",
    "863": "Add new rack",
    "864": "Edit rack",
    "865": "Remove rack",
    "866": "Are you sure you want to delete rack?",
    "867": "Rack name",
    "868": "Rack.ID",
    "869": "Rack link",
    "870": "Rack is in use (closed)",
    "871": "Rack is open",
    "872": "Rack is free",
    "873": "Default status",
    "874": "Rack elements",
    "875": "Rack station",
    "876": "Rack",
    "877": "with the ID ",
    "878": "is closed",
    "879": "You checked in",
    "880": "windows",
    "881": "is opened by station",
    "882": "Could not open rack",
    "883": "Please close rack",
    "884": "Scan Window.ID",
    "885": "Scan Rack.ID",
    "886": "Scan Rack.ID to start",
    "887": "Rack is not available",
    "888": "Error",
    "890": "Window.ID is not scanned",
    "891": "This Element is already scanned",
    "892": "This Element is scanned on rack ",
    "893": "Are you sure you want to upload file?",
    "894": "Empty the rack ?",
    "895": "Switch camera",
    "896": "Scan previous",
    "897": "Scan new",
    "898": "Dealer is required",
    "899": "Add image",
    "900": "Jump to page",
    "901": "Overall Acceptance",
    "902": "Some position elements already have acceptance information entered. Accepting everything will overwrite the existing data.",
    "903": "Impersonating company",
    "904": "Edit landingpage",
    "905": "Information about rack",
    "906": "Empty rack",
    "907": "Rack is empty",
    "908": "Rack status",
    "909": "Closed",
    "910": "Full",
    "911": "In use",
    "912": "Rack pin",
    "913": "Insert rack pin",
    "914": "Wrong pin",
    "915": "Not imported",
    "916": "Standort / Adresse",
    "917": "Auftragnehmer / Firma - Adresse",
    "918": "Element",
    "919": "Acceptance points",
    "920": "own WID logo/colors",
    "921": "Appearance",
    "922": "Monteur signature",
    "923": "Customer signature",
    "924": "Separator for auto upload (Documents)",
    "925": "Please insert global API Key",
    "926": "Maintenance report",
    "927": "Repair report",
    "928": "Please insert char separator for documents",
    "929": "Station not found in database",
    "930": "Barcode is empty",
    "931": "QRCode is in use",
    "932": "ERROR / WARNING",
    "933": "Matching did not work!",
    "934": "Barcode",
    "935": "Window-ID",
    "936": "Error message",
    "937": "Scan to confirm",
    "938": "QR-Code is empty",
    "939": "Detalii tehnice",
    "940": "Delivery",
    "941": "Rack.ID Station",
    "942": "Ignora caracterul (caracterele) de inceput al Rack.ID",
    "943": "Ignora caracterul (caracterele) final(e) al(e) Rack.ID",
    "944": "Rack management",
    "945": "Colour order",
    "946": "Colour Inside / Colour Outside",
    "947": "Colour Outside / Colour Inside",
    "948": "Producator",
    "949": "Factory is required",
    "950": "Matcher - actual version",
    "951": "Assembly incomplete",
    "952": "Scanner settings",
    "953": "Camera selection",
    "954": "Camera",
    "955": "No cameras found.",
    "956": "Press RFID START to start scanner.",
    "957": "Stop preview",
    "958": "Start preview"
};

export default ro;