const pt = {
    "1": "Sim",
    "2": "Não",
    "3": "30-Dias versão em teste",
    "4": "Uma reclamação foi reportada via aplicativo",
    "5": "Aceitação",
    "6": "Data de aceitação",
    "7": "Ação",
    "8": "Ativo",
    "9": "Atividades",
    "10": "Adicionar",
    "11": "Adicionar instalador",
    "12": "Adicionar empresa",
    "13": "Adicionar revendedor/instalador",
    "14": "Adicionar fabricante",
    "15": "Adicionar gerenciamento de propriedade",
    "16": "Adicionar usuário",
    "17": "Campo adicional",
    "18": "Campo adicional edição de nome",
    "19": "Comentários",
    "20": "Informação adicional",
    "21": "Produtos adicionais",
    "22": "Substituir hardware",
    "23": "Administrador",
    "24": "Visão administrativa",
    "25": "Todos os elementos foram entregues no local?",
    "26": "Todas as posições foram instaladas",
    "27": "Todos os direitos reservados",
    "28": "Tem certeza de que deseja excluir este fabricante?",
    "29": "Tem certeza de que deseja excluir este usuário?",
    "30": "Instalado",
    "31": "Instalador",
    "32": "Instaladores",
    "33": "Montagem",
    "34": "Instalação concluída",
    "35": "Data de instalação",
    "36": "Atribuições",
    "37": "Instruções de cuidados",
    "38": "Detalhes do certificado",
    "39": "Certificado(s)",
    "40": "Substituir folha",
    "41": "Limpar",
    "42": "Fechar",
    "43": "Cor",
    "44": "Cor/Acabamento",
    "45": "Comissão",
    "46": "Empresa",
    "47": "Detalhes da empresa",
    "48": "Informações da empresa",
    "49": "Direção da bússola",
    "50": "Reclamações",
    "51": "Projeto concluído",
    "52": "Concluído",
    "53": "Janela conectada",
    "54": "Limpeza do canteiro de obras",
    "55": "Data de criação",
    "56": "Usuário final",
    "57": "Número do cliente",
    "58": "Referência do elemento",
    "59": "Data",
    "60": "Intervalo de datas",
    "61": "Revendedor/Instalador",
    "62": "Excluir empresa",
    "63": "Excluir fabricante",
    "64": "Excluir usuário",
    "65": "Data de entrega",
    "66": "entregue sem danos",
    "67": "Descrição",
    "68": "Local de instalação definido",
    "69": "Exibir registros _MENU_ por página",
    "70": "Distribuição",
    "71": "Documentos",
    "72": "Leste",
    "73": "Editar instalador",
    "74": "Editar fabricante",
    "75": "Editar revendedor",
    "76": "Editar gerenciamento de propriedade",
    "77": "Editar usuário",
    "78": "Largura x altura do elemento",
    "79": "O elemento com esta ID não existe",
    "80": "Elementos",
    "81": "Dados do e-mail salvos",
    "82": "Inserir cor",
    "83": "Inserir comissão",
    "84": "Insira o nome da empresa",
    "85": "Insira o e-mail",
    "86": "Insira o ramal",
    "87": "Insira a altura",
    "88": "Insira o endereço do IP",
    "89": "Insira o nome ",
    "90": "Insira o número",
    "91": "Insira o número de posições",
    "92": "Insira a senha",
    "93": "Insira o telefone",
    "94": "Insira o número do telefone",
    "95": "Insira a cidade",
    "96": "Insira a quantidade",
    "97": "Insira a rua",
    "98": "Insira o sobrenome",
    "99": "Insira o nome do usuário",
    "100": "Insira o ID da conta",
    "101": "Insira o site",
    "102": "Insira a largura",
    "103": "Erro ao salvar dados no servidor.",
    "104": "Erro ao salvar dados do e-mail",
    "105": "Reparar acessórios",
    "106": "Expirado em",
    "107": "Expira em",
    "108": "Extensão",
    "109": "Fabricante",
    "110": "Fábrica",
    "111": "Pesquisa",
    "112": "filtrado de _MAX_ registros totais",
    "113": "Primeiro",
    "114": "Cancelar",
    "115": "Informações de hardware",
    "116": "Telas mosquiteiras",
    "117": "Pegada",
    "118": "Comentários",
    "119": "Do aplicativo",
    "120": "Do ponto de vista da administração",
    "121": "Limpador de vidro",
    "122": "Substituir vidro",
    "123": "Estrutura de vidro",
    "124": "Largura x altura do vidro",
    "125": "Manutenção de hardware",
    "126": "Substituir hardware",
    "127": "Altura",
    "128": "Como limpar",
    "129": "Imagem",
    "130": "Informações sobre o elemento",
    "131": "Informações são restritas",
    "132": "Documentos de posição",
    "134": "Local de instalação",
    "135": "inserir código de barras",
    "136": "Endereço IP",
    "137": "é obrigatório",
    "138": "Idioma",
    "139": "Último",
    "140": "Última manutenção",
    "141": "Última reclamação",
    "142": "Último serviço",
    "143": "Link para o artigo",
    "144": "Carregando",
    "145": "Todos os elementos conforme especificação?",
    "146": "Sair",
    "147": "E-mail - Acessar dados",
    "148": "Manutenção",
    "149": "Alterado por",
    "150": "Fabricado",
    "151": "Design do controlador mestre",
    "152": "Número do material do controlador mestre",
    "153": "Número de série do controlador mestre",
    "154": "Data de fabricação",
    "155": "Visibilidade do menu",
    "156": "Confirmação automática do código de barras",
    "157": "Documentos da página de destino",
    "158": "Novo período de teste",
    "159": "Próximo",
    "160": "Nenhum dano ao elemento",
    "161": "Nenhum dano ao vidro",
    "162": "Nenhum registro correspondente encontrado",
    "163": "Nenhum dos elementos está instalado",
    "164": "Norte",
    "165": "Não instalado",
    "166": "Nada encontrado - desculpe",
    "167": "Número",
    "168": "Número de elementos",
    "169": "Visão geral do projeto",
    "170": "Número de posições",
    "171": "em",
    "172": "Data de criação do pedido",
    "173": "Número do projeto",
    "174": "Progresso do projeto",
    "175": "Saída",
    "176": "Senha",
    "177": "Telefone",
    "178": "Cidade",
    "179": "Limpador de PVC",
    "180": "Documento do elemento",
    "181": "Detalhes do elemento de posição",
    "182": "A posição com este ID não existe",
    "183": "Posições",
    "184": "Anterior",
    "185": "Credenciais do usuário principal",
    "186": "Imprimir dados de login",
    "187": "Processando",
    "188": "Fabricante",
    "189": "Sistema de perfil",
    "190": "Projeto",
    "191": "Número do projeto",
    "192": "Gerenciamento de propriedade",
    "193": "Quantidade",
    "194": "Reclamação",
    "195": "Selecione a estação",
    "196": "Conteúdo reciclado",
    "197": "Reparo",
    "198": "Acessórios de reparo",
    "199": "Superfície de reparo",
    "200": "Relatado do aplicativo",
    "201": "Relatado de acordo com o ponto de vista do gerenciamento",
    "202": "Redefinir período de teste",
    "203": "Resultado",
    "204": "Função",
    "205": "Salvar",
    "206": "Salvo com sucesso.",
    "207": "Superfície limpa",
    "208": "Pesquisar",
    "209": "Nível de segurança",
    "210": "Enviar dados para REHAU",
    "211": "Serviço",
    "212": "Serviço e vendas",
    "213": "Provedor de serviços",
    "214": "Configurações",
    "215": "Deve haver uma aceitação geral manual ou manutenção",
    "216": "Mostrando _INICÍO_ até o_FIM_ do _TOTAL_ dos registros",
    "217": "Mostrando 0 a 0 de 0 registros",
    "218": "Persianas",
    "219": "Entrar",
    "220": "Entrar para iniciar sua sessão",
    "221": "Informações do Smart Glass",
    "222": "Janela inteligente",
    "223": "Alguns elementos foram instalados",
    "224": "Alguns elementos não correspondem",
    "225": "Algumas posições precisam ser instaladas",
    "226": "Sul",
    "227": "Estatísticas",
    "228": "Rua",
    "229": "Sobrenome",
    "230": "Sustentabilidade e energia",
    "231": "Telefone",
    "232": "Período de teste redefinido com sucesso",
    "233": "Período de teste",
    "234": "Reparo concluído",
    "235": "Há reclamações existentes",
    "236": "Não há nenhuma empresa com este ID",
    "237": "Não há informações sobre este elemento",
    "238": "Esta empresa já existe",
    "239": "Título",
    "240": "para",
    "241": "Aceitação geral",
    "242": "Total",
    "243": "Desconhecido",
    "244": "Carregar logotipo",
    "245": "Administração de usuários",
    "246": "Nome de usuário",
    "247": "Nome de usuário já usado",
    "248": "Usuários",
    "249": "Valor U",
    "250": "Valor U (Estrutura)",
    "251": "Valor U (Vidro)",
    "252": "Window.ID atribuído",
    "253": "Aviso para não danificar Window.ID",
    "254": "Bem-vindo",
    "255": "Oeste",
    "256": "Largura",
    "257": "Tamanho geral do elemento (mm)",
    "258": "Área da janela de",
    "259": "Área da janela em",
    "260": "Número do material eletrônico da janela",
    "261": "Número de série eletrônico da janela",
    "262": "Local de instalação da janela",
    "263": "Sistema de janela",
    "264": "Tipo de janela",
    "265": "Substituir folha",
    "266": "Tem certeza?",
    "267": "Limpar filtro",
    "268": "Editar",
    "269": "Exportar CSV",
    "270": "Leitor de código de barras",
    "271": "ID do vidro",
    "272": "Informações",
    "273": "Link",
    "274": "Usuários vinculados",
    "275": "E-mail",
    "276": "Configurações de e-mail",
    "277": "E-mail - Período de teste",
    "278": "Título do serviço",
    "279": "Empresa de serviço",
    "280": "Telefone do serviço",
    "281": "E-mail do serviço",
    "282": "Status",
    "283": "Configurações da estação",
    "284": "Assunto",
    "285": "Telefone",
    "286": "Usuário",
    "287": "Site",
    "288": "ID da janela",
    "289": "Sistema de janela",
    "290": "Detalhes do usuário final",
    "291": "Nome",
    "292": "Estrutura do vidro",
    "293": "Detalhes do hardware",
    "294": "Título do instalador",
    "295": "Empresa instaladora",
    "296": "Telefone do instalador",
    "297": "E-mail do instalador",
    "298": "URL",
    "299": "País",
    "300": "Adicione um usuário para esta empresa",
    "301": "Menu adicional",
    "302": "Painel",
    "303": "Aplicativo",
    "304": "Design de página de destino",
    "305": "Gerenciamento de usuários",
    "306": "Confirmar",
    "307": "Corpo",
    "308": "Anexo",
    "309": "ID",
    "310": "Posição",
    "311": "Aviso",
    "312": "W.ID",
    "314": "Revendedores",
    "315": "Ativar",
    "316": "Desativar",
    "317": "Empresa",
    "318": "Desculpe",
    "319": "Administrador",
    "320": "Usuário",
    "321": "Código postal",
    "322": "Logotipo",
    "323": "Papel de parede",
    "324": "ID da conta",
    "325": "Documentos UKCA",
    "326": "Configurações do aplicativo",
    "327": "Automático",
    "328": "Manual",
    "329": "Navegar",
    "330": "Carregar",
    "331": "Carregar documentos automaticamente",
    "332": "Pasta de origem",
    "333": "Escolha o caminho da pasta para carregar documentos automaticamente.",
    "334": "Escolha o arquivo .zip contendo o arquivo PPS XML",
    "335": "Estação",
    "336": "Digitalização manual",
    "337": "Estação Smart Glass",
    "338": "Transferência de informações",
    "339": "Número máximo de fitas na linha do tempo",
    "340": "QR-Code de",
    "341": "QR-Code para",
    "342": "Prefixo do QR-Code",
    "343": "Comprimento do QR-Code",
    "344": "Dados do código de barras inválidos.",
    "345": "Chave da API",
    "346": "Digite o QR-Code",
    "347": "Nome da estação",
    "348": "Arquivo não selecionado",
    "349": "Arquivo carregado",
    "350": "Erro no carregamento do arquivo",
    "351": "A digitalização manual está habilitada",
    "352": "Digite o QR-Code",
    "353": "O código de barras é inválido",
    "354": "O código de barras é importado",
    "356": "Nenhum dado",
    "357": "A conexão falhou",
    "358": "Histórico",
    "359": "O código de barras está em uso.",
    "360": "Erro ao corresponder ao código de barras",
    "361": "Manual",
    "362": "De",
    "363": "Para",
    "364": "O comprimento do prefixo do QR-Code deve ser menor que o comprimento do QR-Code",
    "365": "O intervalo do código de barras é inválido",
    "366": "Números inválidos",
    "367": "Usado anteriormente",
    "368": "Usado hoje",
    "369": "Grátis",
    "370": "Usado até hoje",
    "371": "Uso do número do QR-Code para o intervalo da estação atual",
    "372": "Traduções",
    "373": "Editar tradução",
    "374": "Chave",
    "375": "Inglês",
    "376": "Valor",
    "377": "Atribuir W.ID",
    "378": "Posições das janelas e portas no projeto",
    "379": "Serviço de reparo / comprovante de reparo",
    "380": "Manutenção e histórico",
    "381": "Relatório de instalação",
    "382": "Modificação",
    "383": "Substituir vidro",
    "384": "Substituir folha",
    "386": "Comentários",
    "387": "Reparo concluído",
    "388": "Novos recursos",
    "389": "Registro de manutenção",
    "390": "Manutenção de hardware",
    "391": "Marcar uma consulta de manutenção",
    "392": "Lista completa do projeto",
    "393": "Informações da janela",
    "394": "Detalhes da instalação",
    "395": "Cor",
    "396": "Largura x altura do elemento em mm",
    "397": "Largura x altura do vidro em mm",
    "398": "Informações de instalação",
    "399": "Nome do instalador",
    "400": "Data/hora da instalação",
    "401": "Local de instalação (GPS)",
    "402": "ID do dispositivo",
    "403": "Enviar",
    "404": "Posição do filtro",
    "405": "Nenhum",
    "406": "Portas de sacada",
    "407": "Cozinha",
    "408": "Banheiro",
    "409": "Sala",
    "410": "Telhado",
    "411": "Instalação",
    "412": "Posição",
    "413": "Anexar imagem",
    "414": "Assinatura",
    "415": "Desenho",
    "416": "Nenhum dano à janela",
    "417": "Nenhum dano ao vidro",
    "418": "Abrir câmera",
    "419": "Carregar",
    "420": "Instalar e atribuir janela",
    "421": "Relatório de aceitação",
    "422": "Enviar",
    "423": "Projeto de construção inteiro",
    "424": "Tipo de montagem",
    "425": "Explícito para elemento",
    "426": "Trabalho completo finalizado",
    "427": "Relatório de instalação para elementos individuais",
    "428": "Problema existe",
    "429": "Solicitar ajuda",
    "430": "Mensagem",
    "431": "Enviar",
    "432": "Próximo elemento",
    "433": "Lista completa de elementos",
    "434": "Cronograma de construção",
    "435": "via número do pedido",
    "436": "via scanner de código QR",
    "437": "exibição individual",
    "438": "ou",
    "439": "Número do código de barras",
    "440": "Mostrar informações",
    "441": "Reparo/reclamação",
    "442": "Mostrar pessoa de contato",
    "443": "Opinião",
    "444": "Escanear outro código QR",
    "445": "Próxima manutenção devida",
    "446": "Fornecedor de janelas",
    "447": "Propriedades",
    "448": "Valor de isolamento acústico",
    "449": "Uf ( fator de transmissão térmica de moldura da janela )",
    "450": "Soluções de retrofit",
    "451": "Contato",
    "452": "Sua ID de janela",
    "453": "O que você gostaria de fazer?",
    "454": "A manutenção regular das janelas/portas estende a vida útil e o conforto",
    "455": "Solicitar agendamento de manutenção por meio do formulário de contato",
    "456": "Solicitar agendamento de manutenção por telefone",
    "457": "para o seu instalador de janelas",
    "458": "Confirmo os termos e condições e o regulamento GDPR",
    "459": "Sua preocupação",
    "461": "Contato via ...",
    "462": "Vidro quebrado",
    "463": "A janela não fecha corretamente",
    "464": "A janela está travada",
    "465": "A janela está danificada",
    "466": "Outros acessórios",
    "467": "Suas janelas e portas foram",
    "468": "montadas por esta empresa",
    "469": "Fabricante de janelas",
    "470": "Avalie suas janelas e instalação agora",
    "471": "Quão satisfeito você está com o serviço da empresa",
    "472": "Os produtos entregues estão OK?",
    "473": "Como você gosta de suas janelas?",
    "474": "Houve algum problema com o processamento?",
    "475": "Com o que você ficou satisfeito?",
    "476": "O que você melhoraria?",
    "477": "Instruções de montagem",
    "478": "Posições das janelas e portas",
    "479": "Abrir o relatório de instalação",
    "480": "Lista completa de elementos do projeto",
    "481": "Lista de elementos via número do pedido / QR",
    "482": "Enviar log do projeto",
    "483": "Detalhes da janela",
    "484": "Detalhes",
    "485": "Número do projeto de construção",
    "486": "Janela",
    "487": "Posição da janela",
    "488": "Serviço de reparo",
    "489": "Detalhes de manutenção",
    "490": "Cliente",
    "491": "Formulário de contato",
    "492": "Encontre um instalador REHAU perto de você",
    "493": "Categoria",
    "494": "QR Scan",
    "495": "Pessoa de contato",
    "496": "atualizar",
    "497": "filtros",
    "498": "A instalação foi concluída",
    "499": "A instalação foi concluída?",
    "500": "Entregue sem danos",
    "501": "Salvar como PDF",
    "502": "Tirar foto",
    "503": "Endereço de e-mail inválido",
    "504": "Instalar aplicativo",
    "505": "Colocar",
    "506": "Lista de projetos",
    "507": "Carregar pedido selecionado?",
    "508": "Número do pedido/código de barras incorreto",
    "509": "Janela única",
    "510": "Inserir alterações",
    "511": "Enviar relatório por e-mail",
    "512": "Selecione 4 opções",
    "513": "Agora escaneie o código QR para descobrir onde o elemento deve ser instalado.",
    "514": "Salvar arquivo",
    "515": "Mostrar",
    "516": "resultados",
    "517": "página",
    "518": "de",
    "519": "Data de",
    "520": "Data para",
    "521": "abrir",
    "522": "Tamanho",
    "523": "Instalado em",
    "524": "Sucesso",
    "525": "Informação",
    "526": "Novo pedido carregado",
    "527": "ID do instalador",
    "528": "Entrar",
    "529": "Confirmo os termos e condições gerais e o regulamento GDPR",
    "530": "Sair",
    "531": "Leitor ",
    "532": "Enviar o relatório",
    "533": "O relatório de aceitação de todos os itens deste pedido deve ser enviado para o painel?",
    "534": "Registro de reparo",
    "535": "opção 5",
    "536": "opção 6",
    "537": "opção 7",
    "538": "opção 8",
    "539": "opção 9",
    "540": "opção 10",
    "541": "E-mail enviado.",
    "542": "Erro ao enviar e-mail!",
    "543": "Manutenção de hardware",
    "544": "Ajustado",
    "545": "Limpo",
    "546": "Selado",
    "547": "Hardware substituído",
    "548": "Comentário",
    "549": "Todos os elementos são entregues no canteiro de obras",
    "550": "Localização dos elementos dentro do projeto de construção",
    "551": "Erro ao enviar o relatório!",
    "552": "Salvar relatório",
    "553": "Informação salva.",
    "554": "Falha ao salvar dados.",
    "555": "Informações da janela inteligente",
    "556": "Aceitação da janela inteligente",
    "557": "Número do material eletrônico da janela",
    "558": "Número de série eletrônico da janela",
    "559": "Leia o código QR (DMC) do controlador mestre ou insira-o manualmente",
    "560": "Janela inteligente totalmente configurada e funcional",
    "561": "Todos os elementos visíveis sem danos/defeitos",
    "562": "Um sistema de comutação foi conectado a 0-10 V (0-100%)?",
    "563": "Local de instalação",
    "564": "Iniciar digitalização",
    "565": "Parar digitalização",
    "566": "Elemento selecionado",
    "567": "Máximo de 4 atributos podem ser selecionados ao mesmo tempo.",
    "568": "Posição do cliente",
    "569": "Data de produção",
    "570": "Alterar Window.ID",
    "571": "Window.ID anterior",
    "572": "New Window.ID",
    "573": "O número do pedido não existe ou o novo número do pedido já está em uso.",
    "574": "Window.ID",
    "575": "ID da posição",
    "576": "Tipo",
    "577": "ID do elemento",
    "578": "Informações do elemento",
    "579": "Window.ID",
    "580": "Por favor, assine no espaço fornecido",
    "581": "Concluído",
    "582": "Assinar",
    "583": "Novo idioma",
    "584": "Idioma original",
    "585": "Nome do idioma",
    "586": "Criar novo idioma",
    "587": "Adicionar idioma",
    "588": "Atualizar",
    "589": "atualização em andamento",
    "590": "Desativação da empresa",
    "591": "Ativação da empresa",
    "592": "Você tem certeza de que deseja desativar esta empresa?",
    "593": "Você tem certeza de que deseja ativar esta empresa?",
    "594": "Desativação do usuário",
    "595": "Ativação do usuário",
    "596": "Você tem certeza de que deseja desativar este usuário?",
    "597": "Você tem certeza de que deseja ativar este usuário?",
    "598": "Posição do ID",
    "599": "Todos",
    "600": "Importar/Exportar",
    "601": "Escolha o arquivo de idioma .csv para importação",
    "602": "Importar",
    "603": "Exportar",
    "604": "Arquivo",
    "605": "Tradutor",
    "606": "Arquivos de código de barras",
    "607": "Escolha o caminho da pasta para os arquivos de código de barras.",
    "608": "Window_ID",
    "609": "Sistema de perfil",
    "610": "Posição do cliente",
    "611": "Cor",
    "612": "Tamanho do elemento",
    "613": "Tamanho do vidro",
    "614": "Estrutura do vidro",
    "615": "Informações do hardware",
    "616": "Data correspondente",
    "617": "Data/hora da instalação",
    "618": "Local de instalação dos dados do GPS",
    "619": "Número:",
    "620": "Instalação:",
    "621": "Piso:",
    "622": "Posição:",
    "623": "Ignorar caractere(s) inicial(ais)",
    "624": "Ignorar caractere(s) final(ais)",
    "625": "E-mail - Ativação do usuário",
    "626": "Ativar empresas",
    "627": "Teste expirado",
    "628": "Reenviar e-mail de ativação",
    "629": "Revendedor/Fábrica já existe",
    "630": "Usuário ativado",
    "631": "Ir para a página de login",
    "632": "Detalhes do usuário",
    "633": "Adicionar usuário vinculado",
    "634": "Excluir link do usuário",
    "635": "O usuário já está vinculado",
    "636": "Nome de usuário ou senha estão vazios",
    "637": "Usuário não encontrado",
    "638": "Enviar protocolo",
    "639": "Pergunta sobre montagem concluída",
    "640": "Opção 5",
    "641": "Opção 6",
    "642": "Opção 7",
    "643": "Opção 8",
    "644": "Opção 9",
    "645": "Opção 10",
    "646": "Montador",
    "647": "Tipo",
    "648": "Data",
    "649": "Descrição",
    "650": "Manutenção de hardware",
    "651": "Definir",
    "652": "Limpar",
    "653": "Vedar",
    "654": "Acessórios de substituição",
    "655": "Comentário",
    "656": "Conector",
    "657": "Sistema de janela",
    "658": "Peça",
    "659": "Número do projeto",
    "660": "ID do pedido",
    "661": "Altura",
    "662": "Largura",
    "663": "Informações de hardware",
    "664": "Largura x altura do vidro",
    "665": "Estrutura do vidro",
    "666": "ID da janela",
    "667": "ID elétrica da janela",
    "668": "ID do vidro",
    "669": "Condições",
    "670": "Adicionar/Remover",
    "671": "E/Ou",
    "672": "Grupo",
    "673": "Coluna",
    "674": "Condição",
    "675": "Digite o número do cliente",
    "676": "Tradutor",
    "678": "Controle de versão do aplicativo",
    "679": "A atualização pode levar alguns minutos para terminar",
    "680": "Aguardando nova versão",
    "681": "Há uma nova versão do aplicativo, tem certeza de que deseja atualizar?",
    "682": "O aplicativo está atualizado, deseja restaurar a versão antiga?",
    "683": "Restaurar versão antiga",
    "684": "Inserir nome da estação",
    "685": "Número do pedido ",
    "686": "Insira o número do pedido ",
    "687": "Comprimento máximo do número do pedido",
    "688": "Insira o comprimento máximo do número do pedido",
    "689": "Caminho da pasta MDB",
    "690": "Insira o caminho do arquivo .mdb (usado pelo importador ao escanear arquivos .mdb)",
    "691": "Caminho da pasta XML",
    "692": "Insira o caminho do arquivo .xml (usado pelo importador ao escanear arquivos .xml)",
    "693": "Ignore os caracteres iniciais do QR-Code",
    "694": "Ignore os caracteres finais do QR-Code",
    "695": "Designação",
    "696": "Comprimento",
    "697": "Posição adicional",
    "698": "Janela inteligente sem danos",
    "699": "Janela inteligente conectada",
    "700": "Orientação da janela",
    "701": "Número do elemento de posição",
    "702": "Janela inteligente configurada",
    "703": "Elemento sem danos",
    "704": "Número do material",
    "705": "Número de série",
    "706": "Nenhuma imagem disponível",
    "707": "Limpeza do canteiro de obras",
    "708": "Arraste e solte os arquivos de imagem/pdf aqui",
    "709": "Carregar arquivo",
    "710": "Tipo de arquivo",
    "711": "Ordem (todas as posições)",
    "712": "Excluído",
    "713": "Notas de versão",
    "714": "Altura (mm)",
    "715": "Largura (mm)",
    "716": "Não há painel para esta posição",
    "717": "Item",
    "718": "Bulk station",
    "719": "Bulk range",
    "720": "ID da janela (último)",
    "721": "O vidro foi substituído",
    "722": "Os acessórios foram reparados",
    "723": "A folha foi substituída",
    "724": "A superfície foi reparada",
    "725": "Os acessórios foram reparados",
    "726": "O reparo foi concluído",
    "727": "Confirmar senha",
    "728": "Senha incorreta",
    "729": "As senhas não correspondem",
    "730": "A senha não pode estar vazia",
    "731": "Localização",
    "732": "Linha do tempo",
    "733": "Valor U (W/m²K)",
    "734": "Vidro especial",
    "735": "Sem posição",
    "736": "Progresso da produção",
    "737": "Produção concluída",
    "738": "Progresso da instalação",
    "739": "Instalação concluída",
    "740": "Estação excluída",
    "741": "Você realmente deseja excluir esta estação?",
    "742": "Adicionar uma nova estação",
    "743": "Excluir pedido",
    "744": "Exclusão do pedido",
    "745": "Tem certeza de que deseja excluir o pedido?",
    "746": "Usuário adicionado",
    "747": "Usuário editado",
    "748": "Esqueceu sua senha?",
    "749": "O campo Senha é obrigatório",
    "750": "O campo Nome do usuário é obrigatório",
    "751": "Esta função de usuário não está autorizada para login no painel",
    "752": "O usuário não está ativo",
    "753": "A empresa do usuário não está ativa ou o período de teste acabou",
    "754": "O nome de usuário ou a senha estão incorretos",
    "755": "Código postal",
    "756": "Use a barra de rolagem para aumentar e diminuir o zoom da imagem",
    "757": "Redefinir senha",
    "758": "O e-mail para redefinição de senha foi enviado",
    "759": "A senha foi redefinida",
    "760": "E-mail - Redefinir senha",
    "761": "Elemento Nr",
    "762": "Número do pedido de",
    "763": "Por favor, insira o número do pedido de",
    "764": "Comprimento do número do pedido",
    "765": "Por favor, insira o comprimento do número do pedido",
    "766": "Intervalo do pedido de",
    "767": "Por favor, insira o intervalo do pedido de",
    "768": "Comprimento do intervalo do pedido",
    "769": "Por favor, insira o comprimento do intervalo do pedido",
    "770": "Please insert order piece from",
    "772": "Comprimento da peça do pedido",
    "773": "Por favor, insira o comprimento da peça do pedido",
    "774": "Número da posição de",
    "775": "Por favor, insira o número da posição de",
    "776": "Comprimento do número da posição",
    "777": "Por favor, insira o comprimento do número da posição",
    "778": "Peça da posição de",
    "779": "Por favor, insira a peça da posição de",
    "780": "Comprimento da peça da posição",
    "781": "Por favor, insira o comprimento da peça da posição",
    "782": "Nível de segurança",
    "783": "Caractere de preenchimento do código de barras",
    "784": "Por favor, insira o caractere de preenchimento do código de barras",
    "785": "Voltar para projetos",
    "786": "(somente leitura)",
    "787": "acesso limitado",
    "788": "acesso somente leitura",
    "789": "página de destino própria",
    "790": "Insira a cadeia de conexão",
    "791": "Cadeia de conexão do BD",
    "792": "Cadeia de conexão do BD STD",
    "793": "Cadeia de conexão do BD BWG",
    "794": "Conexão do banco de dados",
    "795": "Bate-papo",
    "796": "Mensagens",
    "797": "Documento CE",
    "798": "Documento do pedido",
    "799": "Último reparo",
    "800": "Aceitação concluída",
    "801": "Nova atualização disponível, reinicie o aplicativo",
    "802": "Link externo",
    "803": "Prefixo do link externo",
    "804": "Referência do projeto",
    "805": "Intervalo de tarefas",
    "806": "Usuários do painel",
    "807": "Usuários do aplicativo",
    "808": "Último upload",
    "809": "minutos",
    "810": "Versão do combinador",
    "811": "Versão do importador",
    "812": "Sair do usuário",
    "813": "Usuário desconectado com sucesso",
    "814": "Código de barras alterado",
    "815": "Informações atualizadas",
    "816": "Instruções",
    "817": "Instalar APP",
    "818": "Instale o APP Window.ID escaneando o código QR",
    "819": "correspondido",
    "820": "não correspondido",
    "821": "Planejador",
    "822": "Número de dias",
    "823": "Configurações para o lembrete de manutenção",
    "824": "dias",
    "825": "Próxima manutenção",
    "826": "Editar imagem",
    "827": "Sem filtro",
    "828": "Adicionar pedido",
    "829": "Novo pedido",
    "830": "O pedido já existe",
    "831": "A posição já existe",
    "832": "Adicionar novo elemento",
    "833": "Adicionar novo campo",
    "834": "Excluir posição",
    "835": "Editar posição",
    "836": "Adicionar nova posição",
    "837": "Tem certeza de que deseja excluir esta posição?",
    "838": "Idioma padrão",
    "839": "Descrição é necessária",
    "840": "Número é necessário",
    "841": "Editar pedido",
    "842": "Window.ID é necessário",
    "843": "Window.ID já existe",
    "844": "Não há elemento para esta posição",
    "845": "Não há campo de posição",
    "846": "Adicionar/Remover elementos",
    "847": "Adicionar/Remover campos",
    "848": "O campo com esta id não existe",
    "849": "Tipo de campo",
    "850": "Cor do campo",
    "851": "Vidro",
    "852": "Pesquisar Window.ID",
    "853": "pesquisar Window.ID",
    "854": "Copiar do padrão",
    "855": "Prateleira.ID",
    "856": "Nome da prateleira",
    "857": "Vazio",
    "858": "Gerenciamento da prateleira",
    "859": "Entrega concluída sem danos.",
    "860": "Entrega não concluída.",
    "861": "Entrega concluída, mas alguns elementos estão danificados.",
    "862": "Janelas verificadas nesta prateleira",
    "863": "Adicionar nova prateleira",
    "864": "Editar prateleira",
    "865": "Remover prateleira",
    "866": "Tem certeza de que deseja excluir essa prateleira?",
    "867": "Nome da prateleira",
    "868": "Prateleira.ID",
    "869": "Link da prateleira",
    "870": "A prateleira está em uso (fechado)",
    "871": "A prateleira está aberto",
    "872": "a prateleira está livre",
    "873": "Status padrão",
    "874": "Elementos da prateleira",
    "875": "Estação da prateleira",
    "876": "Prateleira",
    "877": "com o ID",
    "878": "está fechado",
    "879": "Você verificou",
    "880": "janelas",
    "881": "está aberto pela estação",
    "882": "Não foi possível abrir a prateleira",
    "883": "Feche a prateleira",
    "884": "Digitalizar Window.ID",
    "885": "Digitalizar prateleira.ID",
    "886": "Digitalizar prateleira.ID para iniciar",
    "887": "Prateleira não está disponível",
    "888": "Erro",
    "890": "Window.ID não foi digitalizado",
    "891": "Este elemento já foi digitalizado",
    "892": "Este elemento foi digitalizado na prateleira",
    "893": "Tem certeza de que deseja enviar o arquivo?",
    "894": "Esvaziar a prateleira?",
    "895": "Trocar câmera",
    "896": "Digitalizar anterior",
    "897": "Digitalizar novo",
    "898": "Revendedor necessário",
    "899": "Adicionar imagem",
    "900": "Ir para a página",
    "901": "Aceitação geral",
    "902": "Alguns elementos de posição já têm informações de aceitação inseridas. Aceitar tudo sobrescreverá os dados existentes.",
    "903": "Página de destino do revendedor",
    "904": "Editar página de destino",
    "905": "Informações sobre a prateleira",
    "906": "Prateleira vazia",
    "907": "Prateleira está vazia",
    "908": "Status da prateleira",
    "909": "Fechado",
    "910": "Cheio",
    "911": "Em uso",
    "912": "Pino da prateleira",
    "913": "Inserir pino da prateleira",
    "914": "Pino errado",
    "915": "Não importado",
    "916": "Localização/Endereço",
    "917": "Contratante/Empresa - Endereço",
    "918": "Elemento",
    "919": "Pontos de aceitação",
    "920": "logotipo/cores WID próprios",
    "921": "Aparência",
    "922": "Assinatura do montador",
    "923": "Assinatura do cliente",
    "924": "Separador para upload automático (Documentos)",
    "925": "Insira a chave global da API",
    "926": "Relatório de manutenção",
    "927": "Relatório de reparo",
    "928": "Insira o separador de caracteres para documentos",
    "929": "Estação não encontrada no banco de dados",
    "930": "O código de barras está vazio",
    "931": "O QRCode está em uso",
    "932": "ERRO / AVISO",
    "933": "A correspondência não funcionou!",
    "934": "Código de barras",
    "935": "ID da janela",
    "936": "Mensagem de erro",
    "937": "Escaneie para confirmar",
    "938": "QR-Code está vazio",
    "939": "Dados técnicos",
    "940": "Delivery",
    "941": "Rack.ID Station",
    "942": "Ignore os caracteres iniciais do Rack.ID",
    "943": "Ignore os caracteres finais do Rack.ID",
    "944": "Rack management",
    "945": "Ordem da cor",
    "946": "Cor interior / Cor exterior",
    "947": "Cor exterior / Cor interior",
    "948": "Fábrica",
    "949": "Factory is required",
    "950": "Matcher - actual version",
    "951": "Assembly incomplete",
    "952": "Scanner settings",
    "953": "Camera selection",
    "954": "Camera",
    "955": "No cameras found.",
    "956": "Press RFID START to start scanner.",
    "957": "Stop preview",
    "958": "Start preview"
};

export default pt;