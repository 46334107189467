const tr = {
    "1": "Evet",
    "2": "Hayır",
    "3": "30 günlük test sürümü",
    "4": "Uygulama üzerinden bir şikayet bildirildi",
    "5": "Kabul",
    "6": "Kabul tarihi",
    "7": "Eylem",
    "8": "Aktif",
    "9": "Aktiviteler",
    "10": "Ekle",
    "11": "Montajcı ekle",
    "12": "Firma ekle",
    "13": "Bayi/montajcı ekle",
    "14": "İmalatçı firma ekle",
    "15": "Mülk yönetimi ekle",
    "16": "Kullanıcı ekle",
    "17": "Ek alan",
    "18": "Ek alan adı düzenleme",
    "19": "Yorumlar",
    "20": "Ek bilgiler",
    "21": "Ek ürünler",
    "22": "Donanımı değiştir",
    "23": "Yönetici",
    "24": "İdari görünüm",
    "25": "Tüm öğeler şantiyeye teslim edildi mi?",
    "26": "Tüm pozisyonlar monte edildi",
    "27": "Tüm hakları saklıdır.",
    "28": "Bu imalatçıyı silmek istediğinizden emin misiniz?",
    "29": "Bu kullanıcıyı silmek istediğinizden emin misiniz?",
    "30": "Monte edildi",
    "31": "Montajcı",
    "32": "Montajcılar",
    "33": "Kurulum",
    "34": "Montaj tamamlandı",
    "35": "Montaj tarihi",
    "36": "Görevler",
    "37": "Bakım talimatları",
    "38": "Sertifika detayları",
    "39": "Sertifika(lar)",
    "40": "Çerçeve değiştir",
    "41": "Temizle",
    "42": "Kapat",
    "43": "Renk",
    "44": "Renk/Cila",
    "45": "Komisyon",
    "46": "Firma",
    "47": "Firma detayları",
    "48": "Firma bilgileri",
    "49": "Pusula yönü",
    "50": "Şikayetler",
    "51": "Proje tamamlandı",
    "52": "Tamamlandı",
    "53": "Bağlantılı Pencere",
    "54": "Şantiye temiz",
    "55": "Oluşturulma tarihi",
    "56": "Son tüketici",
    "57": "Müşteri numarası",
    "58": "Öğe referansı",
    "59": "Tarih",
    "60": "Tarih aralığı",
    "61": "Bayi/montajcı",
    "62": "Firmayı sil",
    "63": "İmalatçıyı sil",
    "64": "Kullanıcıyı sil",
    "65": "Teslim tarihi",
    "66": "Hasar görmeden teslim edildi",
    "67": "Tanımlama",
    "68": "Belirlenmiş montaj yeri",
    "69": "Sayfa başına _MENÜ_ kayıtlarını görüntüle",
    "70": "Dağıtım",
    "71": "Belgeler",
    "72": "Doğu",
    "73": "Montajcıyı düzenle",
    "74": "Firmayı düzenle",
    "75": "Bayiyi düzenle",
    "76": "Mülk yönetimini düzenle",
    "77": "Kullanıcıyı düzenle",
    "78": "Öğe genişliği x yükseklik",
    "79": "Bu kimliğe sahip öğe mevcut değil",
    "80": "Öğeler",
    "81": "E-posta verileri kaydedildi.",
    "82": "Renk gir",
    "83": "Komisyon gir",
    "84": "Firma adını gir",
    "85": "E-posta gir",
    "86": "Uzantı gir",
    "87": "Yükseklik gir",
    "88": "IP adresini gir",
    "89": "İsim gir",
    "90": "Numarayı gir",
    "91": "Pozisyon sayısını gir",
    "92": "Şifre gir",
    "93": "Telefon gir",
    "94": "Telefon numarasını girin",
    "95": "Şehir gir",
    "96": "Miktar gir",
    "97": "Sokak gir",
    "98": "Soyisim gir",
    "99": "Kullanıcı adı gir",
    "100": "Hesap kimliği gir",
    "101": "Web sitesi gir",
    "102": "Genişlik gir",
    "103": "Veriler sunucuya kaydedilirken hata oluştu.",
    "104": "E-posta verileri kaydedilirken hata oluştu.",
    "105": "Tamir aksesuarları",
    "106": "tarihinde süresi doldu",
    "107": "tarihinde süresi dolacak",
    "108": "Uzantı",
    "109": "İmalatçı",
    "110": "Fabrika",
    "111": "Arama",
    "112": "_MAX_ toplam kayıttan filtrelenir",
    "113": "Birinci",
    "114": "İptal",
    "115": "Donanım bilgileri",
    "116": "Sineklikler",
    "117": "Ayakizi",
    "118": "Yorumlar",
    "119": "Uygulamadan",
    "120": "Yönetim bakış açısından",
    "121": "Cam temizleyici",
    "122": "Camı değiştir",
    "123": "Cam yapısı",
    "124": "Cam genişliği x yükseklik",
    "125": "Donanım bakımı",
    "126": "Donanımı değiştir",
    "127": "Yükseklik",
    "128": "Nasıl temizlenir",
    "129": "Görsel",
    "130": "Öğe hakkında bilgi",
    "131": "Bilgi kısıtlıdır",
    "132": "Pozisyon belgeleri",
    "134": "Montaj yeri",
    "135": "barkod gir",
    "136": "IP adresi",
    "137": "gereklidir",
    "138": "Dil",
    "139": "Son",
    "140": "Son bakım",
    "141": "Son şikayet",
    "142": "Son onarım",
    "143": "Makale linki",
    "144": "Yükleniyor",
    "145": "Tüm öğeler şartnameye uygun mu?",
    "146": "Çıkış Yap",
    "147": "E-posta - Verilere erişim",
    "148": "Bakım",
    "149": "Tarafından değiştirildi",
    "150": "Üretildi",
    "151": "Ana denetleyici tasarımı",
    "152": "Ana Denetleyici malzeme numarası",
    "153": "Ana Denetleyici seri numarası",
    "154": "Eşleşen Tarih",
    "155": "Menü görünürlüğü",
    "156": "Barkodu otomatik onayla",
    "157": "Açılış sayfası belgeleri ",
    "158": "Yeni test dönemi",
    "159": "Sonraki",
    "160": "Öğelerde hasar yok",
    "161": "Camda hasar yok",
    "162": "Eşleşen kayıt bulunamadı",
    "163": "Öğelerin hiçbiri monte değil",
    "164": "Kuzey",
    "165": "Monte edilmedi",
    "166": "Hiçbir şey bulunamadı - üzgünüm",
    "167": "Sayı",
    "168": "Öğe sayısı",
    "169": "Projeye genel bakış",
    "170": "Pozisyon sayısı",
    "171": "açık",
    "172": "Sipariş oluşturma tarihi",
    "173": "Proje sayısı",
    "174": "Proje ilerleme durumu",
    "175": "Çıktı",
    "176": "Şifre",
    "177": "Telefon",
    "178": "Şehir",
    "179": "PVC temizleyici",
    "180": "Öğe Belgesi",
    "181": "Pozisyon öğesi detayları",
    "182": "Bu kimliğe sahip pozisyon mevcut değil",
    "183": "Pozisyonlar",
    "184": "Önceki",
    "185": "Birincil kullanıcı kimlik bilgileri",
    "186": "Oturum açma verilerini yazdır",
    "187": "İşleniyor",
    "188": "İmalatçı",
    "189": "Profil sistemi",
    "190": "Proje",
    "191": "Proje No.",
    "192": "Mülk yönetimi",
    "193": "Miktar",
    "194": "Şikayet",
    "195": "Lütfen eşleme istasyonu seçiniz",
    "196": "Geri dönüştürülmüş içerik",
    "197": "Tamir",
    "198": "Tamir aksesuarları",
    "199": "Tamir yüzeyi",
    "200": "Uygulamadan bildirildi",
    "201": "Yönetim görüşünden bildirildi",
    "202": "Test süresini sıfırla",
    "203": "Sonuç",
    "204": "Rol",
    "205": "Kaydet",
    "206": "Başarıyla kaydedildi.",
    "207": "Temiz yüzey",
    "208": "Arama",
    "209": "Güvenlik seviyesi",
    "210": "REHAU'ya veri gönder",
    "211": "Servis",
    "212": "Servis & satış",
    "213": "Servis sağlayıcı",
    "214": "Ayarlar",
    "215": "Manuel bir genel kabul veya bakım",
    "216": "TOPLAM_ kayıtların _BAŞLANGIÇ_ ile _SON_ arası gösteriliyor",
    "217": "0 kayıttan 0 ila 0 arası gösteriliyor",
    "218": "Panjurlar",
    "219": "Oturum Aç",
    "220": "Oturumunuzu başlatmak için giriş yapın",
    "221": "Smart Glass Bilgileri",
    "222": "Smart Window",
    "223": "Bazı öğeler monte edildi",
    "224": "Bazı öğeler eşleşmedi",
    "225": "Bazı pozisyon(lar)ın monte edilmesi gerekiyor",
    "226": "Güney",
    "227": "İstatistikler",
    "228": "Sokak",
    "229": "Soyisim",
    "230": "Sürdürülebilirlik & Enerji",
    "231": "Telefon",
    "232": "Test süresi başarıyla sıfırlandı",
    "233": "test süre(ler)i",
    "234": "Tamir tamamlandı",
    "235": "Mevcut şikayetler var",
    "236": "Bu kimliğe sahip bir şirket yok",
    "237": "Bu öğe hakkında bilgi bulunmamaktadır",
    "238": "Bu firma zaten mevcut",
    "239": "Başlık",
    "240": "e",
    "241": "Genel kabul",
    "242": "Toplam",
    "243": "Bilinmiyor",
    "244": "Logo yükle",
    "245": "Kullanıcı yönetimi",
    "246": "Kullanıcı adı",
    "247": "Kullanıcı adı halihazırda başka bir kullanıcı tarafından kullanılıyor.",
    "248": "Kullanıcılar",
    "249": "U değeri",
    "250": "U-değeri (Çerçeve)",
    "251": "U-değeri (Cam)",
    "252": "W.ID atandı",
    "253": "Window.ID'ye zarar vermemek için uyarı",
    "254": "Hoş geldiniz",
    "255": "Batı",
    "256": "Genişlik",
    "257": "Toplam öğe boyutu (mm)",
    "258": "den pencere alanı",
    "259": "İçindeki pencere alanı",
    "260": "Pencere Elektronik malzeme numarası",
    "261": "Pencere Elektronik seri numarası",
    "262": "Pencere montaj yeri",
    "263": "Pencere sistemi",
    "264": "Pencere tipi",
    "265": "Çerçeve değiştir",
    "266": "Emin misin?",
    "267": "Filtreyi temizle",
    "268": "Düzenle",
    "269": "CSV'yi Dışa Aktar",
    "270": "Barkod okuyucu",
    "271": "Cam ID",
    "272": "Bilgi",
    "273": "Bağlantı",
    "274": "Bağlı kullanıcılar",
    "275": "E-Posta",
    "276": "E-Posta ayarları",
    "277": "E-Posta - Test Süresi",
    "278": "Servis ünvan",
    "279": "Servis firması",
    "280": "Servis telefonu",
    "281": "Servis e-postası",
    "282": "Durum",
    "283": "Eşleme istasyonu ayarları",
    "284": "Konu",
    "285": "Tel.",
    "286": "Kullanıcı",
    "287": "Web sitesi",
    "288": "Window.ID",
    "289": "Pencere Sistemi",
    "290": "Son tüketici detayları",
    "291": "İsim",
    "292": "Cam yapısı",
    "293": "Donanım detayları",
    "294": "Montajcı ünvanı",
    "295": "Montaj firması",
    "296": "Montajcı telefonu",
    "297": "Montajcı e-postası",
    "298": "URL",
    "299": "Ülke",
    "300": "Lütfen bu firma için kullanıcı ekleyin",
    "301": "Ek menü",
    "302": "Gösterge Tablosu",
    "303": "Uygulama",
    "304": "Açılış Sayfası Tasarımı",
    "305": "Kullanıcı yönetimi",
    "306": "Onayla",
    "307": "Esas kısım",
    "308": "Ek",
    "309": "ID",
    "310": "Pozisyon",
    "311": "Uyarı",
    "312": "W.ID",
    "314": "Bayiler",
    "315": "Etkinleştir",
    "316": "Devre dışı bırak",
    "317": "firma",
    "318": "Üzgünüm",
    "319": "Yönetici",
    "320": "kullanıcı",
    "321": "Posta Kodu",
    "322": "Logo",
    "323": "Duvar Kağıdı",
    "324": "Hesap ID",
    "325": "UKCA Belgeleri",
    "326": "Uygulama ayarları",
    "327": "Otomatik",
    "328": "Manuel",
    "329": "Gözat",
    "330": "Yükle",
    "331": "Belgeler otomatik yükleme",
    "332": "Kaynak klasör",
    "333": "Lütfen belgeleri otomatik yüklemek için klasör yolunu seçin.",
    "334": "Lütfen PPS XML dosyasını içeren .zip dosyasını seçin",
    "335": "Eşleme istasyonu",
    "336": "Manuel tarama",
    "337": "Smart Glass İstasyonu",
    "338": "Bilgi transferi",
    "339": "Zaman çizelgesindeki maksimum şerit sayısı",
    "340": "ten QR Kodu",
    "341": "e QR Kodu",
    "342": "QR-Kod öneki",
    "343": "QR-Kod uzunluğu",
    "344": "Barkod verileri geçersiz.",
    "345": "Api anahtarı",
    "346": "qrcode girin",
    "347": "Eşleme istasyonu adı",
    "348": "Dosya seçili değil",
    "349": "Dosya yüklendi",
    "350": "Dosya yükleme hatası",
    "351": "Manuel tarama etkinleştirildi",
    "352": "Lütfen qrcode girin",
    "353": "Barkod geçersiz",
    "354": "Barkod aktarıldı",
    "356": "No data",
    "357": "Connection failed",
    "358": "Geçmiş",
    "359": "Veri yok",
    "360": "Barkod eşleştirilirken hata oluştu",
    "361": "Manuel",
    "362": "den",
    "363": "e",
    "364": "QR-Kod Öneki uzunluğu QR-Kod uzunluğundan daha düşük olmalıdır",
    "365": "Barkod aralığı geçersiz",
    "366": "Geçersiz sayılar",
    "367": "Daha önce kullanılmış",
    "368": "Bugün kullanılmış",
    "369": "Ücretsiz",
    "370": "Bugüne kadar kullanılmış",
    "371": "Mevcut istasyon aralığı için QR-Kod numarası kullanımı",
    "372": "Çeviriler",
    "373": "Çeviriyi düzenle",
    "374": "Anahtar",
    "375": "İngilizce",
    "376": "Değer",
    "377": "W.ID atayın",
    "378": "Projedeki pencere ve kapıların konumları",
    "379": "Tamir hizmeti / tamir kanıtı",
    "380": "Bakım & Geçmiş",
    "381": "Montaj raporu",
    "382": "Değişiklik",
    "383": "Camı değiştir",
    "384": "Çerçeve değiştir",
    "386": "Yorumlar",
    "387": "Tamir tamamlandı",
    "388": "Yeni özellikler",
    "389": "Bakım kaydı",
    "390": "Donanım bakımı",
    "391": "Bir bakım randevusu ayarlayın",
    "392": "Tam proje listesi",
    "393": "Pencere bilgisi",
    "394": "Montaj detayları",
    "395": "Renk",
    "396": "mm cinsinden unsur genişliği x yüksekliği",
    "397": "mm cinsinden cam genişliği x yüksekliği",
    "398": "Montaj bilgisi",
    "399": "Montajcı firma ismi",
    "400": "Montaj tarihi / saati",
    "401": "Montaj yeri (GPS)",
    "402": "Cihaz ID",
    "403": "gönder",
    "404": "Pozisyon filtrele",
    "405": "Hiçbiri",
    "406": "Balkon kapıları",
    "407": "Mutfak",
    "408": "Banyo",
    "409": "Oda",
    "410": "Çatı",
    "411": "Montaj",
    "412": "Pozisyon",
    "413": "Resim ekle",
    "414": "İmza",
    "415": "Çizim",
    "416": "Pencerede hasar yok",
    "417": "Camda hasar yok",
    "418": "Kamerayı aç",
    "419": "Yükle",
    "420": "Pencereyi monte edin ve atayın",
    "421": "Kabul raporu",
    "422": "Gönder",
    "423": "Tüm inşaat projesi",
    "424": "Montaj tipi",
    "425": "Unsur için açık",
    "426": "Tüm iş tamamlandı",
    "427": "Münferit unsurlar için montaj raporu",
    "428": "Sorun var",
    "429": "Yardım talep edin",
    "430": "Mesaj",
    "431": "Gönder",
    "432": "Sonraki Unsur",
    "433": "Tam unsur listesi",
    "434": "İnşaat takvimi",
    "435": "sipariş numarası aracılığıyla",
    "436": "QR kod tarayıcısı aracılığıyla",
    "437": "bireysel ekran",
    "438": "veya",
    "439": "Barkod numarası",
    "440": "Bilgileri göster",
    "441": "Tamir/şikayet",
    "442": "İrtibat kişisini göster",
    "443": "Geri bild",
    "444": "Başka bir QR kodu tarayın",
    "445": "Bir sonraki bakım zamanı",
    "446": "Pencere tedarikçisi",
    "447": "Özellikler",
    "448": "Ses yalıtım değeri",
    "449": "Uf değeri",
    "450": "Retrofit çözümleri",
    "451": "İletişim",
    "452": "Window ID'niz",
    "453": "Ne yapmak istersiniz?",
    "454": "Pencerelerin/kapıların düzenli bakımı kullanım ömrünü ve konforu uzatır",
    "455": "İletişim formu aracılığıyla bakım randevusu talep edin",
    "456": "Telefonla bakım randevusu talep edin",
    "457": "pencere montajcınıza",
    "458": "İşbu belge ile hüküm ve koşulları ve GDPR yönetmeliğini onaylıyorum",
    "459": "Sizin endişeniz",
    "461": "İletişim ...",
    "462": "Kırık cam",
    "463": "Pencere düzgün kapanmıyor",
    "464": "Pencere sıkışmış",
    "465": "Pencere hasarlı",
    "466": "Diğer Aksesuarlar",
    "467": "Pencereleriniz ve kapılarınız",
    "468": "bu firma tarafından monte edildi",
    "469": "Pencere imalatçısı",
    "470": "Pencerelerinizi ve montajınızı şimdi değerlendirin",
    "471": "Şirketin hizmetlerinden ne kadar memnunsunuz?",
    "472": "Teslim edilen ürünler uygun mu?",
    "473": "Pencerelerinizi nasıl buldunuz?",
    "474": "İşlemlerle ilgili herhangi bir sorun yaşandı mı?",
    "475": "Nelerden memnun kaldınız?",
    "476": "Neyi geliştirirdiniz?",
    "477": "Birleştirme talimatları",
    "478": "Pencere ve kapıların pozisyonları",
    "479": "Montaj raporunu aç",
    "480": "Eksiksiz proje unsuru listesi",
    "481": "Sipariş numarası / QR üzerinden unsur listesi",
    "482": "Proje kaydını gönder",
    "483": "Pencere detayları",
    "484": "Detaylar",
    "485": "İnşaat projesi numarası",
    "486": "Pencere",
    "487": "Pencere pozisyonu",
    "488": "Tamir servisi",
    "489": "Bakım detayları",
    "490": "Müşteri",
    "491": "İletişim formu",
    "492": "Yakınınızda bir REHAU Montajcısı bulun ",
    "493": "Kategori",
    "494": "QR Tarama",
    "495": "İrtibat kişisi",
    "496": "güncelle",
    "497": "filtreler",
    "498": "Montaj tamamlandı",
    "499": "Montaj tamamlandı mı?",
    "500": "Hasar görmeden teslim edildi",
    "501": "PDF olarak kaydet",
    "502": "Fotoğraf çek",
    "503": "Geçerli bir e-posta adresi değil",
    "504": "Uygulamayı Yükleyin",
    "505": "Yer",
    "506": "Proje listesi",
    "507": "Seçilen siparişi yükle ?",
    "508": "Yanlış sipariş numarası/barkod",
    "509": "Tek pencere",
    "510": "Değişiklikleri girin",
    "511": "Raporu e-posta ile gönder",
    "512": "Lütfen 4 seçenek seçiniz",
    "513": "Şimdi unsurun nereye monte edilmesi gerektiğini öğrenmek için QR kodunu tarayın.",
    "514": "Dosyayı kaydet",
    "515": "Göster",
    "516": "sonuçlar",
    "517": "sayfa",
    "518": ".",
    "519": "Tarihinden",
    "520": "Tarihine",
    "521": "açık",
    "522": "Boyut",
    "523": "Monte edildi",
    "524": "Başarı",
    "525": "Bilgiler",
    "526": "Yeni sipariş yüklendi",
    "527": "Montajcı ID",
    "528": "Oturumu aç",
    "529": "Genel hüküm ve koşulları ve GDPR yönetmeliğini onaylıyorum",
    "530": "Oturumu kapat",
    "531": "Tarayıcı",
    "532": "Raporu gönder",
    "533": "Bu siparişin tüm kalemleri için kabul raporu gösterge tablosuna gönderilmeli mi?",
    "534": "Tamir kaydı",
    "535": "5. seçenek",
    "536": "6. seçenek",
    "537": "7. seçenek",
    "538": "8. seçenek",
    "539": "9. seçenek",
    "540": "10. seçenek",
    "541": "E-posta gönderildi.",
    "542": "E-posta gönderilirken hata oluştu!",
    "543": "Donanım bakımı",
    "544": "Düzeltildi",
    "545": "Temizlendi",
    "546": "Mühürlendi",
    "547": "Donanım değiştirildi",
    "548": "Yorum",
    "549": "Tüm unsurlar şantiyeye teslim edildi",
    "550": "Unsurların inşaat projesi içindeki konumu",
    "551": "Rapor gönderilirken hata oluştu!",
    "552": "Raporu kaydet.",
    "553": "Bilgiler kaydedildi.",
    "554": "Veri kaydedilemedi.",
    "555": "Smart pencere bilgisi",
    "556": "Smart pencere kabul",
    "557": "Pencere elektroniği malzeme numarası",
    "558": "Pencere elektroniği seri numarası",
    "559": "Ana kontrol ünitesinden QR kodunu (DMC) tarayın veya manuel olarak girin",
    "560": "Smart Pencere tamamen yapılandırılmış ve işlevsel",
    "561": "Tüm görünür unsurlar hasarsız / kusursuz",
    "562": "0-10V'a (%0-100) bir anahtarlama sistemi bağlandı mı?",
    "563": "Montaj konumu",
    "564": "Taramayı başlat",
    "565": "Taramayı durdur",
    "566": "Seçilen Unsur",
    "567": "Aynı anda en fazla 4 özellik seçilebilir.",
    "568": "Müşteri pozisyonu",
    "569": "Üretim tarihi",
    "570": "Window.ID değiştir",
    "571": "Önceki Window.ID",
    "572": "Yeni Window.ID",
    "573": "Sipariş Numarası mevcut değil veya yeni sipariş numarası halihazırda kullanımda.",
    "574": "Window.ID",
    "575": "Pozisyon ID",
    "576": "Tür",
    "577": "Unsur ID",
    "578": "Unsur bilgileri",
    "579": "Window.ID",
    "580": "Lütfen verilen alanı imzalayın",
    "581": "Bitti",
    "582": "İmza",
    "583": "Yeni dil",
    "584": "Orijinal dil",
    "585": "Dil adı",
    "586": "Yeni dil yarat",
    "587": "Dil ekle",
    "588": "Güncelleme",
    "589": "güncelleme devam ediyor",
    "590": "Firma devre dışı bırakma",
    "591": "Firma aktivasyonu",
    "592": "Bu firmayı devre dışı bırakmak istediğinizden emin misiniz?",
    "593": "Bu firmayı aktive etmek istediğinizden emin misiniz?",
    "594": "Kullanıcı devre dışı bırakma",
    "595": "Kullanıcı aktivasyonu",
    "596": "Bu kullanıcıyı devre dışı bırakmak istediğinizden emin misiniz?",
    "597": "Bu kullanıcıyı aktive etmek istediğinizden emin misiniz?",
    "598": "ID Pozisyonu",
    "599": "Hepsi",
    "600": "İçe/Dışa Aktarma",
    "601": "Lütfen içe aktarma için .csv dil dosyasını seçin",
    "602": "İçe aktar",
    "603": "Dışa aktar",
    "604": "Dosya",
    "605": "Çevirmen",
    "606": "Barkod dosyaları",
    "607": "Lütfen barkod dosyaları için klasör yolunu seçin.",
    "608": "Window_ID",
    "609": "Profil sistemi",
    "610": "Müşteri pozisyonu",
    "611": "Renk",
    "612": "Unsur boyutu",
    "613": "Cam boyutu",
    "614": "Cam yapısı",
    "615": "Donanım bilgileri",
    "616": "Eşleme tarihi",
    "617": "Montaj Tarihi/Saati",
    "618": "Montaj konumu GPS verileri",
    "619": "No:",
    "620": "Montaj:",
    "621": "Kat:",
    "622": "Poz:",
    "623": "Başlangıç karakter(ler)ini yoksay",
    "624": "Bitiş karakter(ler)ini yoksay",
    "625": "E-posta - Kullanıcı aktivasyonu",
    "626": "Firmaları aktive et",
    "627": "Deneme süresi doldu",
    "628": "Aktivasyon e-postasını yeniden gönder",
    "629": "Bayi/Fabrika halihazırda mevcut",
    "630": "Kullanıcı aktive edildi",
    "631": "Giriş sayfasına git",
    "632": "Kullanıcı detayları",
    "633": "Bağlantılı kullanıcı ekle",
    "634": "Kullanıcı bağlantısını sil",
    "635": "Kullanıcı halihazırda bağlı",
    "636": "Kullanıcı adı veya şifre boş",
    "637": "Kullanıcı bulunamadı",
    "638": "Protokol gönder",
    "639": "Birleştirme tamamlandı sorusu",
    "640": "5. seçenek",
    "641": "6. seçenek",
    "642": "7. seçenek",
    "643": "8. seçenek",
    "644": "9. seçenek",
    "645": "10. seçenek",
    "646": "Birleştirici",
    "647": "Tür",
    "648": "Tarih",
    "649": "Açıklama",
    "650": "Donanım bakımı",
    "651": "Ayarla",
    "652": "Temizle",
    "653": "Mühürle",
    "654": "Yedek aksesuarlar",
    "655": "Yorum",
    "656": "Bağlayıcı",
    "657": "Pencere Sistemi",
    "658": "Parça",
    "659": "Proje numarası",
    "660": "Sipariş ID",
    "661": "Yükseklik",
    "662": "Genişlik",
    "663": "Donanım bilgileri",
    "664": "Cam genişliği x yüksekliği",
    "665": "Cam Yapısı",
    "666": "Window.ID",
    "667": "Window Elektrik ID",
    "668": "Cam ID",
    "669": "Şartlar",
    "670": "Ekle/Kaldır",
    "671": "Ve/Veya",
    "672": "Grup",
    "673": "Sütun",
    "674": "Şart",
    "675": "Müşteri numarasını girin",
    "676": "Çevirmen",
    "678": "Uygulama sürüm kontrolü",
    "679": "Güncellemenin tamamlanması birkaç dakika sürebilir",
    "680": "Yeni sürüm bekleniyor",
    "681": "Yeni uygulama sürümü var, güncellemek istediğinizden emin misiniz?",
    "682": "Uygulama güncel, eski sürümü geri yüklemek istiyor musunuz?",
    "683": "Eski sürümü geri yükle",
    "684": "İstasyon adını giriniz",
    "685": "Sipariş numarasının karakter dolgusu",
    "686": "Lütfen sipariş numarasının karakter dolgusunu giriniz",
    "687": "Sipariş numarasının maksimum uzunluğu",
    "688": "Lütfen sipariş numarasının maksimum uzunluğunu giriniz",
    "689": "MDB klasör yolu",
    "690": "Lütfen .mdb dosya yolunu ekleyin (.mdb dosyaları için tarama yaparken içe aktarımcı tarafından kullanılır)",
    "691": "XML klasör yolu",
    "692": "Lütfen .xml dosya yolunu ekleyin (.xml dosyaları için tarama yaparken içe aktarımcı tarafından kullanılır)",
    "693": "Başlangıç QR Kodu karakter(ler)ini yoksay",
    "694": "Biten QR Kodu karakter(ler)ini yoksay",
    "695": "Tanımlama",
    "696": "Uzunluk",
    "697": "Ek pozisyon",
    "698": "Hasarsız smart pencere",
    "699": "Smart pencere bağlanmış",
    "700": "Pencere yönü",
    "701": "Pozisyon unsuru numarası",
    "702": "Smart pencere yapılandırılmış",
    "703": "Hasarsız unsur",
    "704": "Malzeme numarası",
    "705": "Seri numarası",
    "706": "Görsel mevcut değil",
    "707": "İnşaat sahası temiz",
    "708": "Görsel/pdf dosyalarını buraya sürükleyip bırakın",
    "709": "Dosya yükleme",
    "710": "Dosya türü",
    "711": "Sipariş (tüm pozisyonlar)",
    "712": "Silindi",
    "713": "Sürüm notları",
    "714": "Yükseklik (mm)",
    "715": "Genişlik (mm)",
    "716": "Bu pozisyon için panel bulunmamaktadır",
    "717": "Öğe",
    "718": "Yığın istasyonu",
    "719": "Yığın aralığı",
    "720": "Window.ID (son)",
    "721": "Cam değiştirildi",
    "722": "Aksesuarlar tamir edildi",
    "723": "Çerçeve değiştirildi",
    "724": "Yüzey tamir edildi",
    "725": "Aksesuarlar tamir edildi",
    "726": "Tamir tamamlandı",
    "727": "Şifreyi onayla",
    "728": "Yanlış şifre",
    "729": "Şifreler eşleşmiyor",
    "730": "Şifre boş bırakılamaz",
    "731": "Konum",
    "732": "Zaman Çizelgesi",
    "733": "U değeri (W/m²K)",
    "734": "Özel cam",
    "735": "Pozisyon yok",
    "736": "Üretim ilerlemesi",
    "737": "Üretim tamamlandı",
    "738": "Montaj ilerlemesi",
    "739": "Montaj tamamlandı",
    "740": "Eşleme istasyonu silindi",
    "741": "Bu eşleme istasyonunu gerçekten silmek istiyor musunuz?",
    "742": "Yeni bir eşleme istasyonu ekleyin",
    "743": "Siparişi sil",
    "744": "Sipariş silme",
    "745": "Siparişi silmek istediğinizden emin misiniz?",
    "746": "Kullanıcı eklendi",
    "747": "Kullanıcı düzenlendi",
    "748": "Şifrenizi mi unuttunuz?",
    "749": "Şifre alanı zorunludur",
    "750": "Kullanıcı adı alanı zorunludur",
    "751": "Bu kullanıcı rolü gösterge tablosuna giriş için yetkili değil",
    "752": "Kullanıcı aktif değil",
    "753": "Kullanıcı firma aktif değil veya test dönemi sona erdi",
    "754": "Kullanıcı adı veya şifre yanlış",
    "755": "Posta kodu",
    "756": "Resmi yakınlaştırmak ve uzaklaştırmak için kaydırma tekerleğini kullanın",
    "757": "Şifreyi sıfırla",
    "758": "Şifre sıfırlama için e-posta gönderildi",
    "759": "Şifre sıfırlandı",
    "760": "E-posta - Şifreyi sıfırla",
    "761": "Unsur No",
    "762": "Sipariş numarası",
    "763": "Lütfen sipariş numarasını giriniz",
    "764": "Sipariş numarası uzunluğu",
    "765": "Lütfen sipariş numarası uzunluğunu giriniz",
    "766": "Sipariş aralığı",
    "767": "Lütfen sipariş aralığını giriniz",
    "768": "Sipariş aralığı uzunluğu",
    "769": "Lütfen sipariş aralığı uzunluğunu giriniz",
    "770": "Please insert order piece from",
    "772": "Sipariş parça uzunluğu",
    "773": "Lütfen sipariş parça uzunluğunu giriniz",
    "774": "Pozisyon numarası",
    "775": "Lütfen pozisyon numarasını giriniz",
    "776": "Pozisyon numarası uzunluğu",
    "777": "Lütfen pozisyon numarası uzunluğunu giriniz",
    "778": "Pozisyon parçası",
    "779": "Lütfen pozisyon parçasını giriniz",
    "780": "Pozisyon parçası uzunluğu",
    "781": "Lütfen pozisyon parçası uzunluğunu giriniz",
    "782": "Güvenlik seviyesi",
    "783": "Barkod doldurma karakteri",
    "784": "Lütfen barkod doldurma karakterini giriniz",
    "785": "Projelere geri dön",
    "786": "(sadece okunur)",
    "787": "sınırlı erişim",
    "788": "sadece okunur erişim",
    "789": "kendi açılış sayfası",
    "790": "Lütfen bağlantı dizesini ekleyin",
    "791": "DB Bağlantı dizesi",
    "792": "DB STD Bağlantı dizesi",
    "793": "DB BWG Bağlantı dizesi",
    "794": "Veritabanı bağlantısı",
    "795": "Sohbet",
    "796": "Mesajlar",
    "797": "CE Belgesi",
    "798": "Sipariş Belgesi",
    "799": "Son tamir",
    "800": "Kabul işlemi tamamlandı",
    "801": "Yeni güncelleme mevcut, lütfen uygulamayı yeniden başlatın",
    "802": "Dış bağlantı",
    "803": "Dış bağlantı öneki",
    "804": "Proje referansı",
    "805": "Görev aralığı",
    "806": "Gösterge tablosu kullanıcıları",
    "807": "Uygulama kullanıcıları",
    "808": "Son yükleme",
    "809": "dakika",
    "810": "Eşleyici sürümü",
    "811": "İçe aktarıcı sürümü",
    "812": "Kullanıcı oturumunu kapat",
    "813": "Kullanıcı başarıyla çıkış yaptı",
    "814": "Barkod değiştirildi",
    "815": "Güncellenmiş bilgiler",
    "816": "Tarif",
    "817": "Uygulamayı Yükleyin",
    "818": "QR kodunu tarayarak Window.ID uygulamasını yükleyin",
    "819": "eşleşti",
    "820": "eşleşmedi",
    "821": "Planlayıcı",
    "822": "Gün sayısı",
    "823": "Bakım hatırlatıcısı için ayarlar",
    "824": "gün",
    "825": "Bir sonraki bakım",
    "826": "Görseli düzenle",
    "827": "Filtresiz",
    "828": "Sipariş ekle",
    "829": "Yeni sipariş",
    "830": "Bu sipariş halihazırda mevcut",
    "831": "Bu pozisyon halihazırda mevcut",
    "832": "Yeni unsur ekle",
    "833": "Yeni alan ekle",
    "834": "Pozisyonu sil",
    "835": "Pozisyonu düzenle",
    "836": "Yeni pozisyon ekle",
    "837": "Bu pozisyonu silmek istediğinizden emin misiniz?",
    "838": "Varsayılan dil",
    "839": "Açıklama gerekli",
    "840": "Sayı gerekli",
    "841": "Siparişi düzenle",
    "842": "Window.ID gerekli",
    "843": "Bu Window.ID halihazırda mevcut",
    "844": "Bu pozisyon için herhangi bir unsur bulunmamaktadır",
    "845": "Pozisyon alanı yok",
    "846": "Unsur Ekle/Kaldır",
    "847": "Alan Ekle/Kaldır",
    "848": "Bu kimliğe sahip alan mevcut değil",
    "849": "Alan tipi",
    "850": "Alan rengi",
    "851": "Cam",
    "852": "Window.ID Ara",
    "853": "Window.ID ara",
    "854": "Varsayılandan kopyala",
    "855": "Rack.ID",
    "856": "Raf ismi",
    "857": "Boş",
    "858": "Raf yönetimi",
    "859": "Teslimat hasarsız olarak tamamlandı.",
    "860": "Teslimat tamamlanmadı.",
    "861": "Teslimat tamamlandı, ancak bazı unsurlar hasar gördü.",
    "862": "Bu rafta check-in yapılan pencereler",
    "863": "Yeni raf ekle",
    "864": "Rafı düzenle",
    "865": "Rafı çıkar",
    "866": "Rafı silmek istediğinizden emin misiniz?",
    "867": "Raf ismi",
    "868": "Rack.ID",
    "869": "Raf linki",
    "870": "Raf kullanımda (kapalı)",
    "871": "Raf açık",
    "872": "Raf boş",
    "873": "Varsayılan durum",
    "874": "Raf unsurları",
    "875": "Raf istasyonu",
    "876": "Raf",
    "877": "ID ile",
    "878": "kapalı",
    "879": "Giriş yaptınız",
    "880": "pencereler",
    "881": "is opened by station",
    "882": "Raf açılamadı",
    "883": "Lütfen rafı kapatın",
    "884": "Window.ID'yi tara",
    "885": "Rack.ID'yi tara",
    "886": "Başlamak için Rack.ID'yi tarayın",
    "887": "Raf mevcut değil",
    "888": "Hata",
    "890": "Window.ID taranmadı",
    "891": "Bu Unsur zaten taranmış",
    "892": "Bu Unsur raf üzerinde taranır ",
    "893": "Dosya yüklemek istediğinizden emin misiniz?",
    "894": "Rafı boşalt ?",
    "895": "Kamera değiştir",
    "896": "Önceki tara",
    "897": "Yeni tara",
    "898": "Bayi gereklidir",
    "899": "Resim ekle",
    "900": "Jump to page",
    "901": "Overall Acceptance",
    "902": "Some position elements already have acceptance information entered. Accepting everything will overwrite the existing data.",
    "903": "Impersonating company",
    "904": "Edit landingpage",
    "905": "Information about rack",
    "906": "Empty rack",
    "907": "Rack is empty",
    "908": "Rack status",
    "909": "Closed",
    "910": "Full",
    "911": "In use",
    "912": "Rack pin",
    "913": "Insert rack pin",
    "914": "Wrong pin",
    "915": "Not imported",
    "916": "Standort / Adresse",
    "917": "Auftragnehmer / Firma - Adresse",
    "918": "Element",
    "919": "Acceptance points",
    "920": "own WID logo/colors",
    "921": "Appearance",
    "922": "Monteur signature",
    "923": "Customer signature",
    "924": "Separator for auto upload (Documents)",
    "925": "Please insert global API Key",
    "926": "Maintenance report",
    "927": "Repair report",
    "928": "Please insert char separator for documents",
    "929": "Station not found in database",
    "930": "Barcode is empty",
    "931": "QRCode is in use",
    "932": "ERROR / WARNING",
    "933": "Matching did not work!",
    "934": "Barcode",
    "935": "Window-ID",
    "936": "Error message",
    "937": "Scan to confirm",
    "938": "QR-Code is empty",
    "939": "Teknik ayrıntılar",
    "940": "Delivery",
    "941": "Rack.ID Station",
    "942": "Ignore Rack.ID starting cahracter(s)",
    "943": "Ignore Rack.ID ending cahracter(s))",
    "944": "Rack management",
    "945": "Colour order",
    "946": "Colour Inside / Colour Outside",
    "947": "Colour Outside / Colour Inside",
    "948": "Fabrika",
    "949": "Factory is required",
    "950": "Matcher - actual version",
    "951": "Assembly incomplete",
    "952": "Scanner settings",
    "953": "Camera selection",
    "954": "Camera",
    "955": "No cameras found.",
    "956": "Press RFID START to start scanner.",
    "957": "Stop preview",
    "958": "Start preview"
};

export default tr;