import { UPDATE_CURRENT_AUFTRAG, UPDATE_CURRENT_BARCODE, INITIALISE_STATE, OPEN_FILTERS, FOTO_ADD, FOTO_REMOVE, UPDATE_PDF, UPDATE_POSITION_ELEMENT_MONTAGE_DATUM,
    UPDATE_MONTAGE_ORT_FILTER, UPDATE_SELECTED_POSITION_ID, SAVE_ABNAHME_POSITION_INFO, UPDATE_SELECTED_ELEMENT_ID,DISABLE_PDF, REMOVE_FOTOS , UPDATE_PDF_POSITION,
    UPDATE_REPAIR_PDF,UPDATE_REPAIR_PDF_POSITION,UPDATE_MAINTENANCE_PDF,UPDATE_MAINTENANCE_PDF_POSITION, UPDATE_SELECTED_DATE_FROM, UPDATE_SELECTED_DATE_TO,
    UPDATE_OFFEN_FILTER, UPDATE_SEARCH_FILTER, UPDATE_PAGE_INDEX, UPDATE_USER_GEOLOCATION, UPDATE_REPAIR_DATA, UPDATE_MAINTENANCE_DATA, UPDATE_LAST_SCANNED_BARCODE, UPDATE_DETAILS_ORIGIN } from '../actions/types';

const initialState = {
    currentAuftrag: {},
    currentBarcode: "",
    filtersOpened: false,
    fotos: [],
    currentPdf: null,
    montageOrtFilter: "",
    currentlySelectedPositionId: null,
    abnahmePositionInfo: [],
    currentlySelectedElementId: null,
    disablePdf:true,
    pdfForPosition: null,
    currentRepairPdf: null,
    repairPdfForPosition:null,
    currentMaintenancePdf: null,
    maintenancePdfForPosition: null,
    selectedDateFrom: new Date("01/01/2023").toISOString(),
    selectedDateTo: new Date("01/01/2023").toISOString(),
    searchFilter: "",
    offenFilter: false,
    pageIndex:0,
    userGeolocation: {latitude: 0, longitude: 0},
    lastSavedRepair:{currentPositionId: -1},
    lastSavedMaintenance:{currentPositionId: -1},
    lastScannedBarcode: "0",
    detailsOrigin: "projectList"

}

export default function task(state = initialState, action) {
    switch (action.type) {
        case INITIALISE_STATE:
            if (!action.payload) return state;
            return Object.assign({}, state, action.payload)
        case UPDATE_CURRENT_AUFTRAG:
            return { ...state, currentAuftrag: action.payload };
        case UPDATE_CURRENT_BARCODE:
            return { ...state, currentBarcode: action.payload };
        case OPEN_FILTERS:
            return { ...state, filtersOpened: action.payload };
        case FOTO_ADD:
            let fotos = state.fotos.slice();
            fotos.push(action.payload);
            return { ...state, fotos: fotos }
        case FOTO_REMOVE:
            return { ...state, fotos: state.fotos.filter(f => f.id !== action.payload) }
        case UPDATE_PDF: {
            return { ...state, currentPdf: action.payload }
        }
        case UPDATE_MONTAGE_ORT_FILTER:{
            return {...state, montageOrtFilter: action.payload}
        }
        case UPDATE_SELECTED_POSITION_ID:{
            return {...state, currentlySelectedPositionId: action.payload}
        }
        case SAVE_ABNAHME_POSITION_INFO:{
            return {...state, abnahmePositionInfo: action.payload}
        }
        case UPDATE_SELECTED_ELEMENT_ID:{
            return {...state, currentlySelectedElementId: action.payload}
        }
        case DISABLE_PDF:{
            return {...state, disablePdf:false}
        }
        case REMOVE_FOTOS:{
            return {...state, fotos: action.payload}
        }
        case UPDATE_PDF_POSITION:{
            return {...state, pdfForPosition: action.payload}
        }
        case UPDATE_POSITION_ELEMENT_MONTAGE_DATUM:{
            let data = action.payload;
            let newTask = Object.assign({}, state.currentAuftrag);
            //console.log("NEWTASK:", newTask);
            let posIndex = newTask.positions.findIndex(x => x.positionId === data.pos);
            let elIndex = newTask.positions[posIndex].positionElements.findIndex(x => x.positionElementId === data.el);
            if(data.val === 'ja')
                newTask.positions[posIndex].positionElements[elIndex].montageDatum = new Date().toISOString();
            else
                newTask.positions[posIndex].positionElements[elIndex].montageDatum = null;
            return {...state, currentAuftrag: newTask}
        }
        case UPDATE_REPAIR_PDF: {
            return { ...state, currentRepairPdf: action.payload }
        }
        case UPDATE_REPAIR_PDF_POSITION:{
            return {...state, repairPdfForPosition: action.payload}
        }
        case UPDATE_MAINTENANCE_PDF: {
            return { ...state, currentMaintenancePdf: action.payload }
        }
        case UPDATE_MAINTENANCE_PDF_POSITION:{
            return {...state, maintenancePdfForPosition: action.payload}
        }
        case UPDATE_SELECTED_DATE_FROM:{
            return {...state, selectedDateFrom: action.payload}
        }
        case UPDATE_SELECTED_DATE_TO:{
            return {...state, selectedDateTo: action.payload}
        }
        case UPDATE_SEARCH_FILTER:{
            return {...state, searchFilter:action.payload}
        }
        case UPDATE_OFFEN_FILTER:{
            return {...state, offenFilter: action.payload}
        }
        case UPDATE_PAGE_INDEX:{
            return {...state, pageIndex: action.payload}
        }
        case UPDATE_USER_GEOLOCATION: {
            return {...state, userGeolocation: action.payload}
        }
        case UPDATE_REPAIR_DATA: {
            return {...state, lastSavedRepair: action.payload}
        }
        case UPDATE_MAINTENANCE_DATA: {
            return {...state, lastSavedMaintenance: action.payload}
        }
        case UPDATE_LAST_SCANNED_BARCODE: {
            return {...state, lastScannedBarcode: action.payload}
        }
        case UPDATE_DETAILS_ORIGIN : {
            return {...state, detailsOrigin: action.payload}
        }
        default:
            return state;
    }
}